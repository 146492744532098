import React, { Component } from "react";
import Header from "./Header";
// import { Bar } from 'react-chartjs-2';
import Loader from "./Loader";
import Reports from "./Reports";
import moment from "moment";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      divisions: [],
      districts: [],
      ars: [],
      selectedDivision: "",
      selectedDistrict: "",
      selectedSociety: "",
      selectedAR: "",
      districtTotal: {
        "HAPPY SEEDER TOTAL COUNT": 0,
        "SUPER SEEDER TOTAL COUNT": 0,
        "SURFACE SEEDER TOTAL COUNT": 0,
        "RMB PLOUGH TOTAL COUNT": 0,
        "ROTAVATOR TOTAL COUNT": 0,
        "ZERO TILL DRILL TOTAL COUNT": 0,
        "CHOPPER TOTAL COUNT": 0,
        "MULCHER TOTAL COUNT": 0,

        "HAPPY SEEDER TOTAL AVG": 0,
        "SUPER SEEDER TOTAL AVG": 0,
        "SURFACE SEEDER TOTAL AVG": 0,
        "RMB PLOUGH TOTAL AVG": 0,
        "ROTAVATOR TOTAL AVG": 0,
        "ZERO TILL DRILL TOTAL AVG": 0,
        "CHOPPER TOTAL AVG": 0,
        "MULCHER TOTAL AVG": 0,
      },
      divisionTotal: {
        "HAPPY SEEDER TOTAL COUNT": 0,
        "SUPER SEEDER TOTAL COUNT": 0,
        "SURFACE SEEDER TOTAL COUNT": 0,
        "RMB PLOUGH TOTAL COUNT": 0,
        "ROTAVATOR TOTAL COUNT": 0,
        "ZERO TILL DRILL TOTAL COUNT": 0,
        "CHOPPER TOTAL COUNT": 0,
        "MULCHER TOTAL COUNT": 0,

        "HAPPY SEEDER TOTAL AVG": 0,
        "SUPER SEEDER TOTAL AVG": 0,
        "SURFACE SEEDER TOTAL AVG": 0,
        "RMB PLOUGH TOTAL AVG": 0,
        "ROTAVATOR TOTAL AVG": 0,
        "ZERO TILL DRILL TOTAL AVG": 0,
        "CHOPPER TOTAL AVG": 0,
        "MULCHER TOTAL AVG": 0,
      },
      arTotal: {
        "HAPPY SEEDER TOTAL COUNT": 0,
        "SUPER SEEDER TOTAL COUNT": 0,
        "SURFACE SEEDER TOTAL COUNT": 0,
        "RMB PLOUGH TOTAL COUNT": 0,
        "ROTAVATOR TOTAL COUNT": 0,
        "ZERO TILL DRILL TOTAL COUNT": 0,
        "CHOPPER TOTAL COUNT": 0,
        "MULCHER TOTAL COUNT": 0,

        "HAPPY SEEDER TOTAL AVG": 0,
        "SUPER SEEDER TOTAL AVG": 0,
        "SURFACE SEEDER TOTAL AVG": 0,
        "RMB PLOUGH TOTAL AVG": 0,
        "ROTAVATOR TOTAL AVG": 0,
        "ZERO TILL DRILL TOTAL AVG": 0,
        "CHOPPER TOTAL AVG": 0,
        "MULCHER TOTAL AVG": 0,
      },
      startDate: new Date("2-Oct-2023").toISOString().split("T")[0],
      // endDate: new Date().toISOString().split('T')[0],
      endDate: new Date("1-Apr-2024").toISOString().split("T")[0],

      // startDate: new Date('2-Apr-2022').toISOString().split('T')[0],
      // endDate: new Date('1-Oct-2022').toISOString().split('T')[0],
      years: [
        // {StartDate:'1-Apr-2021', EndDate:'31-Mar-2022'},
        // {StartDate:'1-Apr-2020', EndDate:'31-Mar-2021'},
        {
          StartDate: "2-Oct-2024",
          EndDate: "1-Apr-2025",
          DisplayStartDate: "1-Oct-2024",
          DisplayEndDate: "31-Mar-2025",
        },
        {
          StartDate: "2-Apr-2024",
          EndDate: "1-Oct-2024",
          DisplayStartDate: "1-Apr-2024",
          DisplayEndDate: "30-Sep-2024",
        },
        {
          StartDate: "2-Oct-2023",
          EndDate: "1-Apr-2024",
          DisplayStartDate: "1-Oct-2023",
          DisplayEndDate: "31-Mar-2024",
        },
        {
          StartDate: "2-Apr-2023",
          EndDate: "1-Oct-2023",
          DisplayStartDate: "1-Apr-2023",
          DisplayEndDate: "30-Sep-2023",
        },
        {
          StartDate: "2-Oct-2022",
          EndDate: "1-Apr-2023",
          DisplayStartDate: "1-Oct-2022",
          DisplayEndDate: "31-Mar-2023",
        },
        {
          StartDate: "2-Apr-2022",
          EndDate: "1-Oct-2022",
          DisplayStartDate: "1-Apr-2022",
          DisplayEndDate: "30-Sep-2022",
        },
        {
          StartDate: "2-Oct-2021",
          EndDate: "1-Apr-2022",
          DisplayStartDate: "1-Oct-2021",
          DisplayEndDate: "31-Mar-2022",
        },
        {
          StartDate: "2-Apr-2021",
          EndDate: "1-Oct-2021",
          DisplayStartDate: "1-Apr-2021",
          DisplayEndDate: "30-Sep-2021",
        },
        {
          StartDate: "2-Oct-2020",
          EndDate: "1-Apr-2021",
          DisplayStartDate: "1-Oct-2020",
          DisplayEndDate: "31-Mar-2021",
        },
      ],
      selectedYearIndex: "0",
      machineTypes: [
        { code: "HAPPYSEEDER", name: "HAPPY SEEDER" },
        { code: "SUPERSEEDER", name: "SUPER SEEDER" },
        { code: "SURFACESEEDER", name: "SURFACE SEEDER" },
        { code: "RMBPLOUGH", name: "RMB PLOUGH" },
        { code: "ROTAVATOR", name: "ROTAVATOR" },
        { code: "ZEROTILLDRILL", name: "ZERO TILL DRILL" },
        // { "code": "CHOPPER", "name": "CHOPPER" },
        { code: "CHOPPER", name: "PADDY STRAW CHOPPER" },
        { code: "MULCHER", name: "MULCHER" },
      ],
      loading: false,
      finalDivisionDashboardData: {},
      happySeederSocieties: [],
      superSeederSocieties: [],
      surfaceSeederSocieties: [],
      rmbPloughSocieties: [],
      rotavatorSocieties: [],
      ztdSocieties: [],
      chopperSocieties: [],
      mulcherSocieties: [],
      districtArray: [],
      societyMachineSummary: [],
      optionSelected: "",
    };
    this.onDivisionSelected = this.onDivisionSelected.bind(this);
    this.onDistrictSelected = this.onDistrictSelected.bind(this);
    this.loadDivisionDashboard = this.loadDivisionDashboard.bind(this);
    this.loadSocietySummary = this.loadSocietySummary.bind(this);
    this.onExportTransactions = this.onExportTransactions.bind(this);

    this.onExportHappySeederSocieties =
      this.onExportHappySeederSocieties.bind(this);
    this.onExportSuperSeederSocieties =
      this.onExportSuperSeederSocieties.bind(this);
    this.onExportSurfaceSeederSocieties =
      this.onExportSurfaceSeederSocieties.bind(this);
    this.onExportRMBPloughSocieties =
      this.onExportRMBPloughSocieties.bind(this);
    this.onExportRotavatorSocieties =
      this.onExportRotavatorSocieties.bind(this);
    this.onExportZTDSocieties = this.onExportZTDSocieties.bind(this);
    this.onExportChopperSocieties = this.onExportChopperSocieties.bind(this);
    this.onExportMulcherSocieties = this.onExportMulcherSocieties.bind(this);

    this.onExportSocietiesSummary = this.onExportSocietiesSummary.bind(this);
    this.renderYearSelection = this.renderYearSelection.bind(this);

    this.objToCSV = this.objToCSV.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  async componentDidMount() {
    this.loadData(this.state.startDate, this.state.endDate);
  }

  async loadData(startDate, endDate) {
    // var s1 = new Date(s);
    // let startDate = new Date(s1.getTime() +(1 *86400000)).toISOString().split('T')[0];

    // var e1 = new Date(e);
    // let endDate = new Date(e1.getTime() +(1 *86400000)).toISOString().split('T')[0];

    // console.log('start date', startDate);
    // console.log('end date', endDate);

    this.setState({ loading: true });
    console.log("this.props.common.user.", this.props.common.user);
    // console.log('this.props.common.user',this.props.common.user)
    if (this.state.optionSelected === "") {
      if (this.props.common.user && this.props.common.user.Role === "RCS") {
        await this.loadDivisionDashboard(
          ["PATIALA", "JALANDHAR", "FEROZEPUR"],
          startDate,
          endDate
        );

        await this.loadSocietySummary(
          ["PATIALA", "JALANDHAR", "FEROZEPUR"],
          startDate,
          endDate
        );
      }
      if (this.props.common.user && this.props.common.user.Role === "JR") {
        let divisions = [];

        divisions.push(this.props.common.user.Division);
        await this.loadDivisionDashboard(divisions, startDate, endDate);

        await this.loadSocietySummary(divisions, startDate, endDate);
      }
      if (this.props.common.user && this.props.common.user.Role === "DR") {
        let districts = await this.props.getDistrictsByDREmailID(
          this.props.common.user.EmailID
        );

        this.onDivisionSelected(null, startDate, endDate, districts);

        let districtArray = [];
        for (let index = 0; index < districts.length; index++) {
          const district = districts[index];
          districtArray.push(district.name);
        }
        this.setState({ districtArray: districtArray });
        this.loadSocietySummary(null, startDate, endDate, districtArray);
      }
    } else if (this.state.optionSelected === "onDivisionSelected") {
      console.log("on division selected");
      this.onDivisionSelected(this.state.selectedDivision, startDate, endDate);
    } else if (this.state.optionSelected === "onDistrictSelected") {
      console.log("on district selected");
      this.onDistrictSelected(this.state.selectedDistrict, startDate, endDate);
    }
    this.setState({ loading: false });
  }

  async onDivisionSelected(division, startDate, endDate, districts = null) {
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    this.setState({ loading: true });
    this.setState({ optionSelected: "onDivisionSelected" });

    if (division) {
      districts = await this.props.getDistrictWiseMachineCountForRCS(division);
    }

    let districtTotal = {
      "HAPPY SEEDER TOTAL COUNT": 0,
      "SUPER SEEDER TOTAL COUNT": 0,
      "SURFACE SEEDER TOTAL COUNT": 0,
      "RMB PLOUGH TOTAL COUNT": 0,
      "ROTAVATOR TOTAL COUNT": 0,
      "ZERO TILL DRILL TOTAL COUNT": 0,
      "CHOPPER TOTAL COUNT": 0,
      "MULCHER TOTAL COUNT": 0,

      "HAPPY SEEDER TOTAL UTILIZATION": 0,
      "SUPER SEEDER TOTAL UTILIZATION": 0,
      "SURFACE SEEDER TOTAL UTILIZATION": 0,
      "RMB PLOUGH TOTAL UTILIZATION": 0,
      "ROTAVATOR TOTAL UTILIZATION": 0,
      "ZERO TILL DRILL TOTAL UTILIZATION": 0,
      "CHOPPER TOTAL UTILIZATION": 0,
      "MULCHER TOTAL UTILIZATION": 0,

      "HAPPY SEEDER TOTAL AVG": 0,
      "SUPER SEEDER TOTAL AVG": 0,
      "SURFACE SEEDER TOTAL AVG": 0,
      "RMB PLOUGH TOTAL AVG": 0,
      "ROTAVATOR TOTAL AVG": 0,
      "ZERO TILL DRILL TOTAL AVG": 0,
      "CHOPPER TOTAL AVG": 0,
      "MULCHER TOTAL AVG": 0,

      "HAPPY SEEDER TOTAL PREV AVG": 0,
      "SUPER SEEDER TOTAL PREV AVG": 0,
      "SURFACE SEEDER TOTAL PREV AVG": 0,
      "RMB PLOUGH TOTAL PREV AVG": 0,
      "ROTAVATOR TOTAL PREV AVG": 0,
      "ZERO TILL DRILL TOTAL PREV AVG": 0,
      "CHOPPER TOTAL PREV AVG": 0,
      "MULCHER TOTAL PREV AVG": 0,
    };

    let finalDistrictData = [];

    let districtArray = [];
    //Pushing Machine Count
    for (let index = 0; index < districts.length; index++) {
      const district = districts[index];
      let data = {
        name: district.name,
        "HAPPY SEEDER COUNT": district["HAPPY SEEDER"],
        "SUPER SEEDER COUNT": district["SUPER SEEDER"],
        "SURFACE SEEDER COUNT": district["SURFACE SEEDER"],
        "RMB PLOUGH COUNT": district["RMB PLOUGH"],
        "ROTAVATOR COUNT": district["ROTAVATOR"],
        "ZERO TILL DRILL COUNT": district["ZERO TILL DRILL"],
        "CHOPPER COUNT": district["CHOPPER"],
        "MULCHER COUNT": district["MULCHER"],

        "HAPPY SEEDER HRS": 0,
        "SUPER SEEDER HRS": 0,
        "SURFACE SEEDER HRS": 0,
        "RMB PLOUGH HRS": 0,
        "ROTAVATOR HRS": 0,
        "ZERO TILL DRILL HRS": 0,
        "CHOPPER HRS": 0,
        "MULCHER HRS": 0,

        "HAPPY SEEDER CHARGEABLE HRS": 0,
        "SUPER SEEDER CHARGEABLE HRS": 0,
        "SURFACE SEEDER CHARGEABLE HRS": 0,
        "RMB PLOUGH CHARGEABLE HRS": 0,
        "ROTAVATOR CHARGEABLE HRS": 0,
        "ZERO TILL DRILL CHARGEABLE HRS": 0,
        "CHOPPER CHARGEABLE HRS": 0,
        "MULCHER CHARGEABLE HRS": 0,

        "HAPPY SEEDER FREE HRS": 0,
        "SUPER SEEDER FREE HRS": 0,
        "SURFACE SEEDER FREE HRS": 0,
        "RMB PLOUGH FREE HRS": 0,
        "ROTAVATOR FREE HRS": 0,
        "ZERO TILL DRILL FREE HRS": 0,
        "CHOPPER FREE HRS": 0,
        "MULCHER FREE HRS": 0,

        "HAPPY SEEDER AVG": 0,
        "SUPER SEEDER AVG": 0,
        "SURFACE SEEDER AVG": 0,
        "RMB PLOUGH AVG": 0,
        "ROTAVATOR AVG": 0,
        "ZERO TILL DRILL AVG": 0,
        "CHOPPER AVG": 0,
        "MULCHER AVG": 0,

        "HAPPY SEEDER PREV AVG": 0,
        "SUPER SEEDER PREV AVG": 0,
        "SURFACE SEEDER PREV AVG": 0,
        "RMB PLOUGH PREV AVG": 0,
        "ROTAVATOR PREV AVG": 0,
        "ZERO TILL DRILL PREV AVG": 0,
        "CHOPPER PREV AVG": 0,
        "MULCHER PREV AVG": 0,
      };
      districtArray.push(data.name);
      finalDistrictData.push(data);
    }

    this.setState({ districtArray: districtArray });
    //
    let districtData = await this.props.getDistrictWiseSummaryForRCS(
      districtArray,
      startDate,
      endDate
    );
    let districtFreeData = await this.props.getDistrictWiseFreeSummaryForRCS(
      districtArray,
      startDate,
      endDate
    );
    // console.log("districtData", districtData);

    //Pushing Chargeable Hrs
    for (let j = 0; j < districtData.length; j++) {
      const data = districtData[j];

      // console.log(data);
      finalDistrictData
        .filter((f) => f.name === data.name)
        .map((m) => {
          m["HAPPY SEEDER HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER HRS"] = data["CHOPPER"];
          m["MULCHER HRS"] = data["MULCHER"];

          m["HAPPY SEEDER CHARGEABLE HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER CHARGEABLE HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER CHARGEABLE HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH CHARGEABLE HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR CHARGEABLE HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL CHARGEABLE HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER CHARGEABLE HRS"] = data["CHOPPER"];
          m["MULCHER CHARGEABLE HRS"] = data["MULCHER"];
        });
    }

    //Pushing Free Hrs
    for (let j = 0; j < districtFreeData.length; j++) {
      const data = districtFreeData[j];

      // console.log(data);
      finalDistrictData
        .filter((f) => f.name === data.name)
        .map((m) => {
          m["HAPPY SEEDER FREE HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER FREE HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER FREE HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH FREE HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR FREE HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL FREE HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER FREE HRS"] = data["CHOPPER"];
          m["MULCHER FREE HRS"] = data["MULCHER"];

          m["HAPPY SEEDER HRS"] =
            Number(m["HAPPY SEEDER HRS"]) + Number(data["HAPPY SEEDER"]);
          m["SUPER SEEDER HRS"] =
            Number(m["SUPER SEEDER HRS"]) + Number(data["SUPER SEEDER"]);
          m["SURFACE SEEDER HRS"] =
            Number(m["SURFACE SEEDER HRS"]) + Number(data["SURFACE SEEDER"]);
          m["RMB PLOUGH HRS"] =
            Number(m["RMB PLOUGH HRS"]) + Number(data["RMB PLOUGH"]);
          m["ROTAVATOR HRS"] =
            Number(m["ROTAVATOR HRS"]) + Number(data["ROTAVATOR"]);
          m["ZERO TILL DRILL HRS"] =
            Number(m["ZERO TILL DRILL HRS"]) + Number(data["ZERO TILL DRILL"]);
          m["CHOPPER HRS"] = Number(m["CHOPPER HRS"]) + Number(data["CHOPPER"]);
          m["MULCHER HRS"] = Number(m["MULCHER HRS"]) + Number(data["MULCHER"]);
        });
    }

    let districtDataPrev = [];
    let districtFreeDataPrev = [];

    // if(this.state.selectedYearIndex==="0"){
    ///

    let previousPeriodIndex = Number(this.state.selectedYearIndex) + 2;
    if (previousPeriodIndex < this.state.years.length) {
      let prevStartDate = new Date(
        this.state.years[Number(this.state.selectedYearIndex) + 2].StartDate
      )
        .toISOString()
        .split("T")[0];
      let prevEndDate = new Date(
        this.state.years[Number(this.state.selectedYearIndex) + 2].EndDate
      )
        .toISOString()
        .split("T")[0];
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      console.log("prevStartDate", prevStartDate);
      console.log("prevEndDate", prevEndDate);

      districtDataPrev = await this.props.getDistrictWiseSummaryForRCS(
        districtArray,
        prevStartDate,
        prevEndDate
      );
      districtFreeDataPrev = await this.props.getDistrictWiseFreeSummaryForRCS(
        districtArray,
        prevStartDate,
        prevEndDate
      );
    }

    let happySeederTotalPrevHRS = 0;
    let supperSeederTotalPrevHRS = 0;
    let supperSMSTotalPrevHRS = 0;
    let rmbTotalPrevHRS = 0;
    let rotavatorTotalPrevHRS = 0;
    let zeroTotalPrevHRS = 0;
    let chopperTotalPrevHRS = 0;
    let mulcherTotalPrevHRS = 0;
    for (let w = 0; w < finalDistrictData.length; w++) {
      const m = finalDistrictData[w];
      m["HAPPY SEEDER AVG"] =
        Number(m["HAPPY SEEDER COUNT"]) > 0
          ? Number(
              Number(m["HAPPY SEEDER HRS"]) / Number(m["HAPPY SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["SUPER SEEDER AVG"] =
        Number(m["SUPER SEEDER COUNT"]) > 0
          ? Number(
              Number(m["SUPER SEEDER HRS"]) / Number(m["SUPER SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["SURFACE SEEDER AVG"] =
        Number(m["SURFACE SEEDER COUNT"]) > 0
          ? Number(
              Number(m["SURFACE SEEDER HRS"]) /
                Number(m["SURFACE SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["RMB PLOUGH AVG"] =
        Number(m["RMB PLOUGH COUNT"]) > 0
          ? Number(
              Number(m["RMB PLOUGH HRS"]) / Number(m["RMB PLOUGH COUNT"])
            ).toFixed(2)
          : 0;
      m["ROTAVATOR AVG"] =
        Number(m["ROTAVATOR COUNT"]) > 0
          ? Number(
              Number(m["ROTAVATOR HRS"]) / Number(m["ROTAVATOR COUNT"])
            ).toFixed(2)
          : 0;
      m["ZERO TILL DRILL AVG"] =
        Number(m["ZERO TILL DRILL COUNT"]) > 0
          ? Number(
              Number(m["ZERO TILL DRILL HRS"]) /
                Number(m["ZERO TILL DRILL COUNT"])
            ).toFixed(2)
          : 0;
      m["CHOPPER AVG"] =
        Number(m["CHOPPER COUNT"]) > 0
          ? Number(
              Number(m["CHOPPER HRS"]) / Number(m["CHOPPER COUNT"])
            ).toFixed(2)
          : 0;
      m["MULCHER AVG"] =
        Number(m["MULCHER COUNT"]) > 0
          ? Number(
              Number(m["MULCHER HRS"]) / Number(m["MULCHER COUNT"])
            ).toFixed(2)
          : 0;

      let result1 = districtDataPrev.filter((f) => f.name === m.name);
      let result2 = districtFreeDataPrev.filter((f) => f.name === m.name);

      if (Number(m["HAPPY SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["HAPPY SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["HAPPY SEEDER"]);
        }
        m["HAPPY SEEDER PREV AVG"] = (
          hrs / Number(m["HAPPY SEEDER COUNT"])
        ).toFixed(2);
        happySeederTotalPrevHRS = Number(happySeederTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["SUPER SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["SUPER SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["SUPER SEEDER"]);
        }
        m["SUPER SEEDER PREV AVG"] = (
          hrs / Number(m["SUPER SEEDER COUNT"])
        ).toFixed(2);
        supperSeederTotalPrevHRS =
          Number(supperSeederTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["SURFACE SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["SURFACE SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["SURFACE SEEDER"]);
        }
        m["SURFACE SEEDER PREV AVG"] = (
          hrs / Number(m["SURFACE SEEDER COUNT"])
        ).toFixed(2);
        supperSMSTotalPrevHRS = Number(supperSMSTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["RMB PLOUGH COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["RMB PLOUGH"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["RMB PLOUGH"]);
        }
        m["RMB PLOUGH PREV AVG"] = (
          hrs / Number(m["RMB PLOUGH COUNT"])
        ).toFixed(2);
        rmbTotalPrevHRS = Number(rmbTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["ROTAVATOR COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["ROTAVATOR"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["ROTAVATOR"]);
        }
        m["ROTAVATOR PREV AVG"] = (hrs / Number(m["ROTAVATOR COUNT"])).toFixed(
          2
        );
        rotavatorTotalPrevHRS = Number(rotavatorTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["ZERO TILL DRILL COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["ZERO TILL DRILL"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["ZERO TILL DRILL"]);
        }
        m["ZERO TILL DRILL PREV AVG"] = (
          hrs / Number(m["ZERO TILL DRILL COUNT"])
        ).toFixed(2);
        zeroTotalPrevHRS = Number(zeroTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["CHOPPER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["CHOPPER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["CHOPPER"]);
        }
        m["CHOPPER PREV AVG"] = (hrs / Number(m["CHOPPER COUNT"])).toFixed(2);
        chopperTotalPrevHRS = Number(chopperTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["MULCHER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["MULCHER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["MULCHER"]);
        }
        m["MULCHER PREV AVG"] = (hrs / Number(m["MULCHER COUNT"])).toFixed(2);
        mulcherTotalPrevHRS = Number(mulcherTotalPrevHRS) + Number(hrs);
      }

      districtTotal["HAPPY SEEDER TOTAL COUNT"] =
        Number(districtTotal["HAPPY SEEDER TOTAL COUNT"]) +
        Number(m["HAPPY SEEDER COUNT"]);
      districtTotal["SUPER SEEDER TOTAL COUNT"] =
        Number(districtTotal["SUPER SEEDER TOTAL COUNT"]) +
        Number(m["SUPER SEEDER COUNT"]);
      districtTotal["SURFACE SEEDER TOTAL COUNT"] =
        Number(districtTotal["SURFACE SEEDER TOTAL COUNT"]) +
        Number(m["SURFACE SEEDER COUNT"]);
      districtTotal["RMB PLOUGH TOTAL COUNT"] =
        Number(districtTotal["RMB PLOUGH TOTAL COUNT"]) +
        Number(m["RMB PLOUGH COUNT"]);
      districtTotal["ROTAVATOR TOTAL COUNT"] =
        Number(districtTotal["ROTAVATOR TOTAL COUNT"]) +
        Number(m["ROTAVATOR COUNT"]);
      districtTotal["ZERO TILL DRILL TOTAL COUNT"] =
        Number(districtTotal["ZERO TILL DRILL TOTAL COUNT"]) +
        Number(m["ZERO TILL DRILL COUNT"]);
      districtTotal["CHOPPER TOTAL COUNT"] =
        Number(districtTotal["CHOPPER TOTAL COUNT"]) +
        Number(m["CHOPPER COUNT"]);
      districtTotal["MULCHER TOTAL COUNT"] =
        Number(districtTotal["MULCHER TOTAL COUNT"]) +
        Number(m["MULCHER COUNT"]);

      districtTotal["HAPPY SEEDER TOTAL UTILIZATION"] =
        Number(districtTotal["HAPPY SEEDER TOTAL UTILIZATION"]) +
        Number(m["HAPPY SEEDER HRS"]);
      districtTotal["SUPER SEEDER TOTAL UTILIZATION"] =
        Number(districtTotal["SUPER SEEDER TOTAL UTILIZATION"]) +
        Number(m["SUPER SEEDER HRS"]);
      districtTotal["SURFACE SEEDER TOTAL UTILIZATION"] =
        Number(districtTotal["SURFACE SEEDER TOTAL UTILIZATION"]) +
        Number(m["SURFACE SEEDER HRS"]);
      districtTotal["RMB PLOUGH TOTAL UTILIZATION"] =
        Number(districtTotal["RMB PLOUGH TOTAL UTILIZATION"]) +
        Number(m["RMB PLOUGH HRS"]);
      districtTotal["ROTAVATOR TOTAL UTILIZATION"] =
        Number(districtTotal["ROTAVATOR TOTAL UTILIZATION"]) +
        Number(m["ROTAVATOR HRS"]);
      districtTotal["ZERO TILL DRILL TOTAL UTILIZATION"] =
        Number(districtTotal["ZERO TILL DRILL TOTAL UTILIZATION"]) +
        Number(m["ZERO TILL DRILL HRS"]);
      districtTotal["CHOPPER TOTAL UTILIZATION"] =
        Number(districtTotal["CHOPPER TOTAL UTILIZATION"]) +
        Number(m["CHOPPER HRS"]);
      districtTotal["MULCHER TOTAL UTILIZATION"] =
        Number(districtTotal["MULCHER TOTAL UTILIZATION"]) +
        Number(m["MULCHER HRS"]);
    }

    districtTotal["HAPPY SEEDER TOTAL AVG"] =
      Number(districtTotal["HAPPY SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["HAPPY SEEDER TOTAL UTILIZATION"]) /
              Number(districtTotal["HAPPY SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["SUPER SEEDER TOTAL AVG"] =
      Number(districtTotal["SUPER SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["SUPER SEEDER TOTAL UTILIZATION"]) /
              districtTotal["SUPER SEEDER TOTAL COUNT"]
          ).toFixed(2)
        : 0;
    districtTotal["SURFACE SEEDER TOTAL AVG"] =
      Number(districtTotal["SURFACE SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["SURFACE SEEDER TOTAL UTILIZATION"]) /
              Number(districtTotal["SURFACE SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["RMB PLOUGH TOTAL AVG"] =
      Number(districtTotal["RMB PLOUGH TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["RMB PLOUGH TOTAL UTILIZATION"]) /
              Number(districtTotal["RMB PLOUGH TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["ROTAVATOR TOTAL AVG"] =
      Number(districtTotal["ROTAVATOR TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["ROTAVATOR TOTAL UTILIZATION"]) /
              Number(districtTotal["ROTAVATOR TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["ZERO TILL DRILL TOTAL AVG"] =
      Number(districtTotal["ZERO TILL DRILL TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["ZERO TILL DRILL TOTAL UTILIZATION"]) /
              Number(districtTotal["ZERO TILL DRILL TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["CHOPPER TOTAL AVG"] =
      Number(districtTotal["CHOPPER TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["CHOPPER TOTAL UTILIZATION"]) /
              Number(districtTotal["CHOPPER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["MULCHER TOTAL AVG"] =
      Number(districtTotal["MULCHER TOTAL COUNT"]) > 0
        ? Number(
            Number(districtTotal["MULCHER TOTAL UTILIZATION"]) /
              Number(districtTotal["MULCHER TOTAL COUNT"])
          ).toFixed(2)
        : 0;

    districtTotal["HAPPY SEEDER TOTAL PREV AVG"] =
      Number(districtTotal["HAPPY SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(happySeederTotalPrevHRS) /
              Number(districtTotal["HAPPY SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["SUPER SEEDER TOTAL PREV AVG"] =
      Number(districtTotal["SUPER SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(supperSeederTotalPrevHRS) /
              districtTotal["SUPER SEEDER TOTAL COUNT"]
          ).toFixed(2)
        : 0;
    districtTotal["SURFACE SEEDER TOTAL PREV AVG"] =
      Number(districtTotal["SURFACE SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(supperSMSTotalPrevHRS) /
              Number(districtTotal["SURFACE SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["RMB PLOUGH TOTAL PREV AVG"] =
      Number(districtTotal["RMB PLOUGH TOTAL COUNT"]) > 0
        ? Number(
            Number(rmbTotalPrevHRS) /
              Number(districtTotal["RMB PLOUGH TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["ROTAVATOR TOTAL PREV AVG"] =
      Number(districtTotal["ROTAVATOR TOTAL COUNT"]) > 0
        ? Number(
            Number(rotavatorTotalPrevHRS) /
              Number(districtTotal["ROTAVATOR TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["ZERO TILL DRILL TOTAL PREV AVG"] =
      Number(districtTotal["ZERO TILL DRILL TOTAL COUNT"]) > 0
        ? Number(
            Number(zeroTotalPrevHRS) /
              Number(districtTotal["ZERO TILL DRILL TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["CHOPPER TOTAL PREV AVG"] =
      Number(districtTotal["CHOPPER TOTAL COUNT"]) > 0
        ? Number(
            Number(chopperTotalPrevHRS) /
              Number(districtTotal["CHOPPER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    districtTotal["MULCHER TOTAL PREV AVG"] =
      Number(districtTotal["MULCHER TOTAL COUNT"]) > 0
        ? Number(
            Number(mulcherTotalPrevHRS) /
              Number(districtTotal["MULCHER TOTAL COUNT"])
          ).toFixed(2)
        : 0;

    this.setState({
      loading: false,
      selectedDivision: division,
      districts: finalDistrictData,
      districtTotal: districtTotal,
    });
  }

  async loadDivisionDashboard(divisonArray, startDate, endDate) {
    console.log("loadDivisionDashboard");
    let divisions = await this.props.getDivisionWiseMachineCountForRCS(
      divisonArray
    );

    // console.log("divi sions", JSON.stringify(divisions));

    let finalDivisionData = [];

    let divisionTotal = {
      "HAPPY SEEDER TOTAL COUNT": 0,
      "SUPER SEEDER TOTAL COUNT": 0,
      "SURFACE SEEDER TOTAL COUNT": 0,
      "RMB PLOUGH TOTAL COUNT": 0,
      "ROTAVATOR TOTAL COUNT": 0,
      "ZERO TILL DRILL TOTAL COUNT": 0,
      "CHOPPER TOTAL COUNT": 0,
      "MULCHER TOTAL COUNT": 0,

      "HAPPY SEEDER TOTAL AVG": 0,
      "SUPER SEEDER TOTAL AVG": 0,
      "SURFACE SEEDER TOTAL AVG": 0,
      "RMB PLOUGH TOTAL AVG": 0,
      "ROTAVATOR TOTAL AVG": 0,
      "ZERO TILL DRILL TOTAL AVG": 0,
      "CHOPPER TOTAL AVG": 0,
      "MULCHER TOTAL AVG": 0,

      "HAPPY SEEDER TOTAL PREV AVG": 0,
      "SUPER SEEDER TOTAL PREV AVG": 0,
      "SURFACE SEEDER TOTAL PREV AVG": 0,
      "RMB PLOUGH TOTAL PREV AVG": 0,
      "ROTAVATOR TOTAL PREV AVG": 0,
      "ZERO TILL DRILL TOTAL PREV AVG": 0,
      "CHOPPER TOTAL PREV AVG": 0,
      "MULCHER TOTAL PREV AVG": 0,

      "HAPPY SEEDER TOTAL UTILIZATION": 0,
      "SUPER SEEDER TOTAL UTILIZATION": 0,
      "SURFACE SEEDER TOTAL UTILIZATION": 0,
      "RMB PLOUGH TOTAL UTILIZATION": 0,
      "ROTAVATOR TOTAL UTILIZATION": 0,
      "ZERO TILL DRILL TOTAL UTILIZATION": 0,
      "CHOPPER TOTAL UTILIZATION": 0,
      "MULCHER TOTAL UTILIZATION": 0,

      "HAPPY SEEDER TOTAL FREE HRS": 0,
      "SUPER SEEDER TOTAL FREE HRS": 0,
      "SURFACE SEEDER TOTAL FREE HRS": 0,
      "RMB PLOUGH TOTAL FREE HRS": 0,
      "ROTAVATOR TOTAL FREE HRS": 0,
      "ZERO TILL DRILL TOTAL FREE HRS": 0,
      "CHOPPER TOTAL FREE HRS": 0,
      "MULCHER TOTAL FREE HRS": 0,

      "HAPPY SEEDER TOTAL CHARGEABLE HRS": 0,
      "SUPER SEEDER TOTAL CHARGEABLE HRS": 0,
      "SURFACE SEEDER TOTAL CHARGEABLE HRS": 0,
      "RMB PLOUGH TOTAL CHARGEABLE HRS": 0,
      "ROTAVATOR TOTAL CHARGEABLE HRS": 0,
      "ZERO TILL DRILL TOTAL CHARGEABLE HRS": 0,
      "CHOPPER TOTAL CHARGEABLE HRS": 0,
      "MULCHER TOTAL CHARGEABLE HRS": 0,
    };

    //Pushing Machine Count
    for (let index = 0; index < divisions.length; index++) {
      const division = divisions[index];
      let data = {
        name: division.name,
        "HAPPY SEEDER COUNT": division["HAPPY SEEDER"],
        "SUPER SEEDER COUNT": division["SUPER SEEDER"],
        "SURFACE SEEDER COUNT": division["SURFACE SEEDER"],
        "RMB PLOUGH COUNT": division["RMB PLOUGH"],
        "ROTAVATOR COUNT": division["ROTAVATOR"],
        "ZERO TILL DRILL COUNT": division["ZERO TILL DRILL"],
        "CHOPPER COUNT": division["CHOPPER"],
        "MULCHER COUNT": division["MULCHER"],

        "HAPPY SEEDER HRS": 0,
        "SUPER SEEDER HRS": 0,
        "SURFACE SEEDER HRS": 0,
        "RMB PLOUGH HRS": 0,
        "ROTAVATOR HRS": 0,
        "ZERO TILL DRILL HRS": 0,
        "CHOPPER HRS": 0,
        "MULCHER HRS": 0,

        "HAPPY SEEDER CHARGEABLE HRS": 0,
        "SUPER SEEDER CHARGEABLE HRS": 0,
        "SURFACE SEEDER CHARGEABLE HRS": 0,
        "RMB PLOUGH CHARGEABLE HRS": 0,
        "ROTAVATOR CHARGEABLE HRS": 0,
        "ZERO TILL DRILL CHARGEABLE HRS": 0,
        "CHOPPER CHARGEABLE HRS": 0,
        "MULCHER CHARGEABLE HRS": 0,

        "HAPPY SEEDER FREE HRS": 0,
        "SUPER SEEDER FREE HRS": 0,
        "SURFACE SEEDER FREE HRS": 0,
        "RMB PLOUGH FREE HRS": 0,
        "ROTAVATOR FREE HRS": 0,
        "ZERO TILL DRILL FREE HRS": 0,
        "CHOPPER FREE HRS": 0,
        "MULCHER FREE HRS": 0,

        "HAPPY SEEDER AVG": 0,
        "SUPER SEEDER AVG": 0,
        "SURFACE SEEDER AVG": 0,
        "RMB PLOUGH AVG": 0,
        "ROTAVATOR AVG": 0,
        "ZERO TILL DRILL AVG": 0,
        "CHOPPER AVG": 0,
        "MULCHER AVG": 0,

        "HAPPY SEEDER PREV AVG": 0,
        "SUPER SEEDER PREV AVG": 0,
        "SURFACE SEEDER PREV AVG": 0,
        "RMB PLOUGH PREV AVG": 0,
        "ROTAVATOR PREV AVG": 0,
        "ZERO TILL DRILL PREV AVG": 0,
        "CHOPPER PREV AVG": 0,
        "MULCHER PREV AVG": 0,
      };
      finalDivisionData.push(data);
    }

    // console.log("finalDivisionData", JSON.stringify(finalDivisionData));
    let divisionData = await this.props.getDivisionWiseSummaryForRCS(
      divisonArray,
      startDate,
      endDate
    );
    let divisionFreeData = await this.props.getDivisionWiseFreeSummaryForRCS(
      divisonArray,
      startDate,
      endDate
    );

    console.log("divisionData", divisionData);

    for (let j = 0; j < divisionData.length; j++) {
      const data = divisionData[j];

      finalDivisionData
        .filter((f) => f.name === data.name)
        .map((m) => {
          m["HAPPY SEEDER HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER HRS"] = data["CHOPPER"];
          m["MULCHER HRS"] = data["MULCHER"];

          m["HAPPY SEEDER CHARGEABLE HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER CHARGEABLE HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER CHARGEABLE HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH CHARGEABLE HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR CHARGEABLE HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL CHARGEABLE HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER CHARGEABLE HRS"] = data["CHOPPER"];
          m["MULCHER CHARGEABLE HRS"] = data["MULCHER"];
        });
    }

    //Pushing Free Hrs
    for (let j = 0; j < divisionFreeData.length; j++) {
      const data = divisionFreeData[j];

      // console.log(data);
      finalDivisionData
        .filter((f) => f.name === data.name)
        .map((m) => {
          m["HAPPY SEEDER FREE HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER FREE HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER FREE HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH FREE HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR FREE HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL FREE HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER FREE HRS"] = data["CHOPPER"];
          m["MULCHER FREE HRS"] = data["MULCHER"];

          m["HAPPY SEEDER HRS"] =
            Number(m["HAPPY SEEDER HRS"]) + Number(data["HAPPY SEEDER"]);
          m["SUPER SEEDER HRS"] =
            Number(m["SUPER SEEDER HRS"]) + Number(data["SUPER SEEDER"]);
          m["SURFACE SEEDER HRS"] =
            Number(m["SURFACE SEEDER HRS"]) + Number(data["SURFACE SEEDER"]);
          m["RMB PLOUGH HRS"] =
            Number(m["RMB PLOUGH HRS"]) + Number(data["RMB PLOUGH"]);
          m["ROTAVATOR HRS"] =
            Number(m["ROTAVATOR HRS"]) + Number(data["ROTAVATOR"]);
          m["ZERO TILL DRILL HRS"] =
            Number(m["ZERO TILL DRILL HRS"]) + Number(data["ZERO TILL DRILL"]);
          m["CHOPPER HRS"] = Number(m["CHOPPER HRS"]) + Number(data["CHOPPER"]);
          m["MULCHER HRS"] = Number(m["MULCHER HRS"]) + Number(data["MULCHER"]);
        });
    }

    let machinesUtilization = {
      labels: [],
      datasets: [
        {
          label: "Free Hrs",
          data: [],
          backgroundColor: "#FF6384",
        },
        {
          label: "Chargeable Hrs",
          data: [],
          backgroundColor: "#36A2EB",
        },
      ],
    };
    let divisionDashboardData1 = [];

    for (let j = 0; j < this.state.machineTypes.length; j++) {
      const machineType = this.state.machineTypes[j];
      divisionDashboardData1.push({
        Machine: machineType.name,
        FreeHrs: 0,
        ChargeableHrs: 0,
      });
    }

    //Pushing Chargeable Hrs
    let divisionDataPrev = [];
    let divisionFreeDataPrev = [];

    //  if(this.state.selectedYearIndex==="0"){
    let previousPeriodIndex = Number(this.state.selectedYearIndex) + 2;
    if (previousPeriodIndex < this.state.years.length) {
      ///
      let prevStartDate = new Date(
        this.state.years[Number(this.state.selectedYearIndex) + 2].StartDate
      )
        .toISOString()
        .split("T")[0];
      let prevEndDate = new Date(
        this.state.years[Number(this.state.selectedYearIndex) + 2].EndDate
      )
        .toISOString()
        .split("T")[0];
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      console.log("prevStartDate", prevStartDate);
      console.log("prevEndDate", prevEndDate);

      divisionDataPrev = await this.props.getDivisionWiseSummaryForRCS(
        divisonArray,
        prevStartDate,
        prevEndDate
      );
      divisionFreeDataPrev = await this.props.getDivisionWiseFreeSummaryForRCS(
        divisonArray,
        prevStartDate,
        prevEndDate
      );
    }

    let happySeederTotalPrevHRS = 0;
    let supperSeederTotalPrevHRS = 0;
    let supperSMSTotalPrevHRS = 0;
    let rmbTotalPrevHRS = 0;
    let rotavatorTotalPrevHRS = 0;
    let zeroTotalPrevHRS = 0;
    let chopperTotalPrevHRS = 0;
    let mulcherTotalPrevHRS = 0;
    for (let w = 0; w < finalDivisionData.length; w++) {
      const m = finalDivisionData[w];
      m["HAPPY SEEDER AVG"] =
        Number(m["HAPPY SEEDER COUNT"]) > 0
          ? Number(
              Number(m["HAPPY SEEDER HRS"]) / Number(m["HAPPY SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["SUPER SEEDER AVG"] =
        Number(m["SUPER SEEDER COUNT"]) > 0
          ? Number(
              Number(m["SUPER SEEDER HRS"]) / Number(m["SUPER SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["SURFACE SEEDER AVG"] =
        Number(m["SURFACE SEEDER COUNT"]) > 0
          ? Number(
              Number(m["SURFACE SEEDER HRS"]) /
                Number(m["SURFACE SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["RMB PLOUGH AVG"] =
        Number(m["RMB PLOUGH COUNT"]) > 0
          ? Number(
              Number(m["RMB PLOUGH HRS"]) / Number(m["RMB PLOUGH COUNT"])
            ).toFixed(2)
          : 0;
      m["ROTAVATOR AVG"] =
        Number(m["ROTAVATOR COUNT"]) > 0
          ? Number(
              Number(m["ROTAVATOR HRS"]) / Number(m["ROTAVATOR COUNT"])
            ).toFixed(2)
          : 0;
      m["ZERO TILL DRILL AVG"] =
        Number(m["ZERO TILL DRILL COUNT"]) > 0
          ? Number(
              Number(m["ZERO TILL DRILL HRS"]) /
                Number(m["ZERO TILL DRILL COUNT"])
            ).toFixed(2)
          : 0;
      m["CHOPPER AVG"] =
        Number(m["CHOPPER COUNT"]) > 0
          ? Number(
              Number(m["CHOPPER HRS"]) / Number(m["CHOPPER COUNT"])
            ).toFixed(2)
          : 0;
      m["MULCHER AVG"] =
        Number(m["MULCHER COUNT"]) > 0
          ? Number(
              Number(m["MULCHER HRS"]) / Number(m["MULCHER COUNT"])
            ).toFixed(2)
          : 0;

      let result1 = divisionDataPrev.filter((f) => f.name === m.name);
      let result2 = divisionFreeDataPrev.filter((f) => f.name === m.name);

      if (Number(m["HAPPY SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["HAPPY SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["HAPPY SEEDER"]);
        }
        m["HAPPY SEEDER PREV AVG"] = (
          hrs / Number(m["HAPPY SEEDER COUNT"])
        ).toFixed(2);
        happySeederTotalPrevHRS = Number(happySeederTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["SUPER SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["SUPER SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["SUPER SEEDER"]);
        }
        m["SUPER SEEDER PREV AVG"] = (
          hrs / Number(m["SUPER SEEDER COUNT"])
        ).toFixed(2);
        supperSeederTotalPrevHRS =
          Number(supperSeederTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["SURFACE SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["SURFACE SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["SURFACE SEEDER"]);
        }
        m["SURFACE SEEDER PREV AVG"] = (
          hrs / Number(m["SURFACE SEEDER COUNT"])
        ).toFixed(2);
        supperSMSTotalPrevHRS = Number(supperSMSTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["RMB PLOUGH COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["RMB PLOUGH"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["RMB PLOUGH"]);
        }
        m["RMB PLOUGH PREV AVG"] = (
          hrs / Number(m["RMB PLOUGH COUNT"])
        ).toFixed(2);
        rmbTotalPrevHRS = Number(rmbTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["ROTAVATOR COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["ROTAVATOR"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["ROTAVATOR"]);
        }
        m["ROTAVATOR PREV AVG"] = (hrs / Number(m["ROTAVATOR COUNT"])).toFixed(
          2
        );
        rotavatorTotalPrevHRS = Number(rotavatorTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["ZERO TILL DRILL COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["ZERO TILL DRILL"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["ZERO TILL DRILL"]);
        }
        m["ZERO TILL DRILL PREV AVG"] = (
          hrs / Number(m["ZERO TILL DRILL COUNT"])
        ).toFixed(2);
        zeroTotalPrevHRS = Number(zeroTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["CHOPPER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["CHOPPER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["CHOPPER"]);
        }
        m["CHOPPER PREV AVG"] = (hrs / Number(m["CHOPPER COUNT"])).toFixed(2);
        chopperTotalPrevHRS = Number(chopperTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["MULCHER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["MULCHER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["MULCHER"]);
        }
        m["MULCHER PREV AVG"] = (hrs / Number(m["MULCHER COUNT"])).toFixed(2);
        mulcherTotalPrevHRS = Number(mulcherTotalPrevHRS) + Number(hrs);
      }

      divisionDashboardData1
        .filter((f) => f.Machine === "HAPPY SEEDER")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["HAPPY SEEDER FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) + Number(m["HAPPY SEEDER CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "SUPER SEEDER")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["SUPER SEEDER FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) + Number(m["SUPER SEEDER CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "SURFACE SEEDER")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["SURFACE SEEDER FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) +
            Number(m["SURFACE SEEDER CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "RMB PLOUGH")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["RMB PLOUGH FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) + Number(m["RMB PLOUGH CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "ROTAVATOR")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["ROTAVATOR FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) + Number(m["ROTAVATOR CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "ZERO TILL DRILL")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["ZERO TILL DRILL FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) +
            Number(m["ZERO TILL DRILL CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "CHOPPER")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["CHOPPER FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) + Number(m["CHOPPER CHARGEABLE HRS"]);
        });

      divisionDashboardData1
        .filter((f) => f.Machine === "MULCHER")
        .map((p) => {
          p.FreeHrs = Number(p.FreeHrs) + Number(m["MULCHER FREE HRS"]);
          p.ChargeableHrs =
            Number(p.ChargeableHrs) + Number(m["MULCHER CHARGEABLE HRS"]);
        });

      divisionTotal["HAPPY SEEDER TOTAL FREE HRS"] =
        Number(divisionTotal["HAPPY SEEDER TOTAL FREE HRS"]) +
        Number(m["HAPPY SEEDER FREE HRS"]);
      divisionTotal["SUPER SEEDER TOTAL FREE HRS"] =
        Number(divisionTotal["SUPER SEEDER TOTAL FREE HRS"]) +
        Number(m["SUPER SEEDER FREE HRS"]);
      divisionTotal["SURFACE SEEDER TOTAL FREE HRS"] =
        Number(divisionTotal["SURFACE SEEDER TOTAL FREE HRS"]) +
        Number(m["SURFACE SEEDER FREE HRS"]);
      divisionTotal["RMB PLOUGH TOTAL FREE HRS"] =
        Number(divisionTotal["RMB PLOUGH TOTAL FREE HRS"]) +
        Number(m["RMB PLOUGH FREE HRS"]);
      divisionTotal["ROTAVATOR TOTAL FREE HRS"] =
        Number(divisionTotal["ROTAVATOR TOTAL FREE HRS"]) +
        Number(m["ROTAVATOR FREE HRS"]);
      divisionTotal["ZERO TILL DRILL TOTAL FREE HRS"] =
        Number(divisionTotal["ZERO TILL DRILL TOTAL FREE HRS"]) +
        Number(m["ZERO TILL DRILL FREE HRS"]);
      divisionTotal["CHOPPER TOTAL FREE HRS"] =
        Number(divisionTotal["CHOPPER TOTAL FREE HRS"]) +
        Number(m["CHOPPER FREE HRS"]);
      divisionTotal["MULCHER TOTAL FREE HRS"] =
        Number(divisionTotal["MULCHER TOTAL FREE HRS"]) +
        Number(m["MULCHER FREE HRS"]);

      divisionTotal["HAPPY SEEDER TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["HAPPY SEEDER TOTAL CHARGEABLE HRS"]) +
        Number(m["HAPPY SEEDER CHARGEABLE HRS"]);
      divisionTotal["SUPER SEEDER TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["SUPER SEEDER TOTAL CHARGEABLE HRS"]) +
        Number(m["SUPER SEEDER CHARGEABLE HRS"]);
      divisionTotal["SURFACE SEEDER TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["SURFACE SEEDER TOTAL CHARGEABLE HRS"]) +
        Number(m["SURFACE SEEDER CHARGEABLE HRS"]);
      divisionTotal["RMB PLOUGH TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["RMB PLOUGH TOTAL CHARGEABLE HRS"]) +
        Number(m["RMB PLOUGH CHARGEABLE HRS"]);
      divisionTotal["ROTAVATOR TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["ROTAVATOR TOTAL CHARGEABLE HRS"]) +
        Number(m["ROTAVATOR CHARGEABLE HRS"]);
      divisionTotal["ZERO TILL DRILL TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["ZERO TILL DRILL TOTAL CHARGEABLE HRS"]) +
        Number(m["ZERO TILL DRILL CHARGEABLE HRS"]);
      divisionTotal["CHOPPER TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["CHOPPER TOTAL CHARGEABLE HRS"]) +
        Number(m["CHOPPER CHARGEABLE HRS"]);
      divisionTotal["MULCHER TOTAL CHARGEABLE HRS"] =
        Number(divisionTotal["MULCHER TOTAL CHARGEABLE HRS"]) +
        Number(m["MULCHER CHARGEABLE HRS"]);

      divisionTotal["HAPPY SEEDER TOTAL COUNT"] =
        Number(divisionTotal["HAPPY SEEDER TOTAL COUNT"]) +
        Number(m["HAPPY SEEDER COUNT"]);
      divisionTotal["SUPER SEEDER TOTAL COUNT"] =
        Number(divisionTotal["SUPER SEEDER TOTAL COUNT"]) +
        Number(m["SUPER SEEDER COUNT"]);
      divisionTotal["SURFACE SEEDER TOTAL COUNT"] =
        Number(divisionTotal["SURFACE SEEDER TOTAL COUNT"]) +
        Number(m["SURFACE SEEDER COUNT"]);
      divisionTotal["RMB PLOUGH TOTAL COUNT"] =
        Number(divisionTotal["RMB PLOUGH TOTAL COUNT"]) +
        Number(m["RMB PLOUGH COUNT"]);
      divisionTotal["ROTAVATOR TOTAL COUNT"] =
        Number(divisionTotal["ROTAVATOR TOTAL COUNT"]) +
        Number(m["ROTAVATOR COUNT"]);
      divisionTotal["ZERO TILL DRILL TOTAL COUNT"] =
        Number(divisionTotal["ZERO TILL DRILL TOTAL COUNT"]) +
        Number(m["ZERO TILL DRILL COUNT"]);
      divisionTotal["CHOPPER TOTAL COUNT"] =
        Number(divisionTotal["CHOPPER TOTAL COUNT"]) +
        Number(m["CHOPPER COUNT"]);
      divisionTotal["MULCHER TOTAL COUNT"] =
        Number(divisionTotal["MULCHER TOTAL COUNT"]) +
        Number(m["MULCHER COUNT"]);

      divisionTotal["HAPPY SEEDER TOTAL UTILIZATION"] =
        Number(divisionTotal["HAPPY SEEDER TOTAL UTILIZATION"]) +
        Number(m["HAPPY SEEDER HRS"]);
      divisionTotal["SUPER SEEDER TOTAL UTILIZATION"] =
        Number(divisionTotal["SUPER SEEDER TOTAL UTILIZATION"]) +
        Number(m["SUPER SEEDER HRS"]);
      divisionTotal["SURFACE SEEDER TOTAL UTILIZATION"] =
        Number(divisionTotal["SURFACE SEEDER TOTAL UTILIZATION"]) +
        Number(m["SURFACE SEEDER HRS"]);
      divisionTotal["RMB PLOUGH TOTAL UTILIZATION"] =
        Number(divisionTotal["RMB PLOUGH TOTAL UTILIZATION"]) +
        Number(m["RMB PLOUGH HRS"]);
      divisionTotal["ROTAVATOR TOTAL UTILIZATION"] =
        Number(divisionTotal["ROTAVATOR TOTAL UTILIZATION"]) +
        Number(m["ROTAVATOR HRS"]);
      divisionTotal["ZERO TILL DRILL TOTAL UTILIZATION"] =
        Number(divisionTotal["ZERO TILL DRILL TOTAL UTILIZATION"]) +
        Number(m["ZERO TILL DRILL HRS"]);
      divisionTotal["CHOPPER TOTAL UTILIZATION"] =
        Number(divisionTotal["CHOPPER TOTAL UTILIZATION"]) +
        Number(m["CHOPPER HRS"]);
      divisionTotal["MULCHER TOTAL UTILIZATION"] =
        Number(divisionTotal["MULCHER TOTAL UTILIZATION"]) +
        Number(m["MULCHER HRS"]);
    }

    divisionTotal["HAPPY SEEDER TOTAL AVG"] =
      Number(divisionTotal["HAPPY SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["HAPPY SEEDER TOTAL UTILIZATION"]) /
              Number(divisionTotal["HAPPY SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["SUPER SEEDER TOTAL AVG"] =
      Number(divisionTotal["SUPER SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["SUPER SEEDER TOTAL UTILIZATION"]) /
              divisionTotal["SUPER SEEDER TOTAL COUNT"]
          ).toFixed(2)
        : 0;
    divisionTotal["SURFACE SEEDER TOTAL AVG"] =
      Number(divisionTotal["SURFACE SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["SURFACE SEEDER TOTAL UTILIZATION"]) /
              Number(divisionTotal["SURFACE SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["RMB PLOUGH TOTAL AVG"] =
      Number(divisionTotal["RMB PLOUGH TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["RMB PLOUGH TOTAL UTILIZATION"]) /
              Number(divisionTotal["RMB PLOUGH TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["ROTAVATOR TOTAL AVG"] =
      Number(divisionTotal["ROTAVATOR TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["ROTAVATOR TOTAL UTILIZATION"]) /
              Number(divisionTotal["ROTAVATOR TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["ZERO TILL DRILL TOTAL AVG"] =
      Number(divisionTotal["ZERO TILL DRILL TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["ZERO TILL DRILL TOTAL UTILIZATION"]) /
              Number(divisionTotal["ZERO TILL DRILL TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["CHOPPER TOTAL AVG"] =
      Number(divisionTotal["CHOPPER TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["CHOPPER TOTAL UTILIZATION"]) /
              Number(divisionTotal["CHOPPER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["MULCHER TOTAL AVG"] =
      Number(divisionTotal["MULCHER TOTAL COUNT"]) > 0
        ? Number(
            Number(divisionTotal["MULCHER TOTAL UTILIZATION"]) /
              Number(divisionTotal["MULCHER TOTAL COUNT"])
          ).toFixed(2)
        : 0;

    divisionTotal["HAPPY SEEDER TOTAL PREV AVG"] =
      Number(divisionTotal["HAPPY SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(happySeederTotalPrevHRS) /
              Number(divisionTotal["HAPPY SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["SUPER SEEDER TOTAL PREV AVG"] =
      Number(divisionTotal["SUPER SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(supperSeederTotalPrevHRS) /
              divisionTotal["SUPER SEEDER TOTAL COUNT"]
          ).toFixed(2)
        : 0;
    divisionTotal["SURFACE SEEDER TOTAL PREV AVG"] =
      Number(divisionTotal["SURFACE SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(supperSMSTotalPrevHRS) /
              Number(divisionTotal["SURFACE SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["RMB PLOUGH TOTAL PREV AVG"] =
      Number(divisionTotal["RMB PLOUGH TOTAL COUNT"]) > 0
        ? Number(
            Number(rmbTotalPrevHRS) /
              Number(divisionTotal["RMB PLOUGH TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["ROTAVATOR TOTAL PREV AVG"] =
      Number(divisionTotal["ROTAVATOR TOTAL COUNT"]) > 0
        ? Number(
            Number(rotavatorTotalPrevHRS) /
              Number(divisionTotal["ROTAVATOR TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["ZERO TILL DRILL TOTAL PREV AVG"] =
      Number(divisionTotal["ZERO TILL DRILL TOTAL COUNT"]) > 0
        ? Number(
            Number(zeroTotalPrevHRS) /
              Number(divisionTotal["ZERO TILL DRILL TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["CHOPPER TOTAL PREV AVG"] =
      Number(divisionTotal["CHOPPER TOTAL COUNT"]) > 0
        ? Number(
            Number(chopperTotalPrevHRS) /
              Number(divisionTotal["CHOPPER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    divisionTotal["MULCHER TOTAL PREV AVG"] =
      Number(divisionTotal["MULCHER TOTAL COUNT"]) > 0
        ? Number(
            Number(mulcherTotalPrevHRS) /
              Number(divisionTotal["MULCHER TOTAL COUNT"])
          ).toFixed(2)
        : 0;

    for (let i = 0; i < divisionDashboardData1.length; i++) {
      const objData = divisionDashboardData1[i];

      machinesUtilization.labels.push(objData.Machine);

      for (let j = 0; j < this.state.machineTypes.length; j++) {
        machinesUtilization.datasets[0].data.push(0);
        machinesUtilization.datasets[1].data.push(0);
      }
      machinesUtilization.datasets[0].data[i] = Number(
        objData["FreeHrs"]
      ).toFixed(2);
      machinesUtilization.datasets[1].data[i] = Number(
        objData["ChargeableHrs"]
      ).toFixed(2);
    }

    // console.log("machinesUtilization", JSON.stringify(machinesUtilization));

    this.setState({
      divisions: finalDivisionData,
      finalDivisionDashboardData: machinesUtilization,
      divisionTotal: divisionTotal,
    });
  }

  async loadSocietySummary(
    divisonArray,
    startDate,
    endDate,
    districtArray = null
  ) {
    this.setState({ loading: true });

    let societyMachineSummary = [];

    if (divisonArray)
      societyMachineSummary = await this.props.getSocietyWiseMachineCount(
        divisonArray
      );

    if (districtArray)
      societyMachineSummary =
        await this.props.getSocietyWiseMachineCountForDisctricts(districtArray);

    let happySeederSocieties = [];
    let superSeederSocieties = [];
    let surfaceSeederSocieties = [];
    let rmbPloughSocieties = [];
    let rotavatorSocieties = [];
    let ztdSocieties = [];
    let chopperSocieties = [];
    let mulcherSocieties = [];

    let finalSummary = [];

    for (let index = 0; index < societyMachineSummary.length; index++) {
      const objSociety = societyMachineSummary[index];

      let objFinal = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "HAPPY SEEDER COUNT": objSociety["HAPPY SEEDER"],
        "HAPPY SEEDER TOTAL HRS": 0,
        "HAPPY SEEDER CHARGEABLE HRS": 0,
        "HAPPY SEEDER FREE HRS": 0,
        "HAPPY SEEDER AVG": 0,
        "HAPPY SEEDER UTILIZATION": "0 Utilization",

        "SUPER SEEDER COUNT": objSociety["SUPER SEEDER"],
        "SUPER SEEDER TOTAL HRS": 0,
        "SUPER SEEDER CHARGEABLE HRS": 0,
        "SUPER SEEDER FREE HRS": 0,
        "SUPER SEEDER AVG": 0,
        "SUPER SEEDER UTILIZATION": "0 Utilization",

        "SURFACE SEEDER COUNT": objSociety["SURFACE SEEDER"],
        "SURFACE SEEDER TOTAL HRS": 0,
        "SURFACE SEEDER CHARGEABLE HRS": 0,
        "SURFACE SEEDER FREE HRS": 0,
        "SURFACE SEEDER AVG": 0,
        "SURFACE SEEDER UTILIZATION": "0 Utilization",

        "RMB PLOUGH COUNT": objSociety["RMB PLOUGH"],
        "RMB PLOUGH TOTAL HRS": 0,
        "RMB PLOUGH CHARGEABLE HRS": 0,
        "RMB PLOUGH FREE HRS": 0,
        "RMB PLOUGH AVG": 0,
        "RMB PLOUGH UTILIZATION": "0 Utilization",

        "ROTAVATOR COUNT": objSociety["ROTAVATOR"],
        "ROTAVATOR TOTAL HRS": 0,
        "ROTAVATOR CHARGEABLE HRS": 0,
        "ROTAVATOR FREE HRS": 0,
        "ROTAVATOR AVG": 0,
        "ROTAVATOR UTILIZATION": "0 Utilization",

        "ZERO TILL DRILL COUNT": objSociety["ZERO TILL DRILL"],
        "ZERO TILL DRILL TOTAL HRS": 0,
        "ZERO TILL DRILL CHARGEABLE HRS": 0,
        "ZERO TILL DRILL FREE HRS": 0,
        "ZERO TILL DRILL AVG": 0,
        "ZERO TILL DRILL UTILIZATION": "0 Utilization",

        "CHOPPER COUNT": objSociety["CHOPPER"],
        "CHOPPER TOTAL HRS": 0,
        "CHOPPER CHARGEABLE HRS": 0,
        "CHOPPER FREE HRS": 0,
        "CHOPPER AVG": 0,
        "CHOPPER UTILIZATION": "0 Utilization",

        "MULCHER COUNT": objSociety["MULCHER"],
        "MULCHER TOTAL HRS": 0,
        "MULCHER CHARGEABLE HRS": 0,
        "MULCHER FREE HRS": 0,
        "MULCHER AVG": 0,
        "MULCHER UTILIZATION": "0 Utilization",
      };

      finalSummary.push(objFinal);

      let happySeederSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "HAPPY SEEDER COUNT": objSociety["HAPPY SEEDER"],
        "HAPPY SEEDER HRS": 0,
        "HAPPY SEEDER AVG": 0,
        "HAPPY SEEDER UTILIZATION": "0 Utilization",
      };

      let superSeederSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "SUPER SEEDER COUNT": objSociety["SUPER SEEDER"],
        "SUPER SEEDER HRS": 0,
        "SUPER SEEDER AVG": 0,
        "SUPER SEEDER UTILIZATION": "0 Utilization",
      };

      let surfaceSeederSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "SURFACE SEEDER COUNT": objSociety["SURFACE SEEDER"],
        "SURFACE SEEDER HRS": 0,
        "SURFACE SEEDER AVG": 0,
        "SURFACE SEEDER UTILIZATION": "0 Utilization",
      };

      let rmbPloughSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "RMB PLOUGH COUNT": objSociety["RMB PLOUGH"],
        "RMB PLOUGH HRS": 0,
        "RMB PLOUGH AVG": 0,
        "RMB PLOUGH UTILIZATION": "0 Utilization",
      };

      let rotavatorSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "ROTAVATOR COUNT": objSociety["ROTAVATOR"],
        "ROTAVATOR HRS": 0,
        "ROTAVATOR AVG": 0,
        "ROTAVATOR UTILIZATION": "0 Utilization",
      };

      let ztdSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "ZERO TILL DRILL COUNT": objSociety["ZERO TILL DRILL"],
        "ZERO TILL DRILL HRS": 0,
        "ZERO TILL DRILL AVG": 0,
        "ZERO TILL DRILL UTILIZATION": "0 Utilization",
      };

      let chopperSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "CHOPPER COUNT": objSociety["CHOPPER"],
        "CHOPPER HRS": 0,
        "CHOPPER AVG": 0,
        "CHOPPER UTILIZATION": "0 Utilization",
      };

      let mulcherSociety = {
        id: objSociety.id,
        society: objSociety.name,
        district: objSociety.district,
        division: objSociety.division,
        ar: objSociety.ar,

        "MULCHER COUNT": objSociety["MULCHER"],
        "MULCHER HRS": 0,
        "MULCHER AVG": 0,
        "MULCHER UTILIZATION": "0 Utilization",
      };

      if (happySeederSociety["HAPPY SEEDER COUNT"] > 0) {
        happySeederSocieties.push(happySeederSociety);
      }

      if (superSeederSociety["SUPER SEEDER COUNT"] > 0) {
        superSeederSocieties.push(superSeederSociety);
      }

      if (surfaceSeederSociety["SURFACE SEEDER COUNT"] > 0) {
        surfaceSeederSocieties.push(surfaceSeederSociety);
      }

      if (rmbPloughSociety["RMB PLOUGH COUNT"] > 0) {
        rmbPloughSocieties.push(rmbPloughSociety);
      }

      if (rotavatorSociety["ROTAVATOR COUNT"] > 0) {
        rotavatorSocieties.push(rotavatorSociety);
      }

      if (ztdSociety["ZERO TILL DRILL COUNT"] > 0) {
        ztdSocieties.push(ztdSociety);
      }

      if (chopperSociety["CHOPPER COUNT"] > 0) {
        chopperSocieties.push(chopperSociety);
      }

      if (mulcherSociety["MULCHER COUNT"] > 0) {
        mulcherSocieties.push(mulcherSociety);
      }
    }

    let happySocieties = [];
    for (let j = 0; j < happySeederSocieties.length; j++) {
      const happySeederSociety = happySeederSocieties[j];
      happySocieties.push(happySeederSociety.id);
    }

    let happySeederHrsSummary =
      await this.props.getSocieytWiseSummaryForSocieties(
        happySocieties,
        "HAPPYSEEDER",
        startDate,
        endDate
      );
    let happySeederFreeHRSSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        happySocieties,
        "HAPPYSEEDER",
        startDate,
        endDate
      );
    // console.log("happySeederHrsSummary", JSON.stringify(happySeederHrsSummary));
    for (let h = 0; h < happySeederHrsSummary.length; h++) {
      let happySeederHRS = happySeederHrsSummary[h];

      happySeederSocieties
        .filter((f) => f.id === happySeederHRS.id)
        .map((m) => {
          m["HAPPY SEEDER HRS"] = happySeederHRS["HAPPY SEEDER"];

          m["HAPPY SEEDER AVG"] =
            Number(m["HAPPY SEEDER COUNT"]) > 0
              ? Number(
                  Number(m["HAPPY SEEDER HRS"]) /
                    Number(m["HAPPY SEEDER COUNT"])
                ).toFixed(2)
              : 0;
          m["HAPPY SEEDER UTILIZATION"] =
            Number(m["HAPPY SEEDER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["HAPPY SEEDER AVG"]) > 30 &&
                Number(m["HAPPY SEEDER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["HAPPY SEEDER AVG"]) < 31 &&
                Number(m["HAPPY SEEDER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === happySeederHRS.id)
        .map((m) => {
          m["HAPPY SEEDER TOTAL HRS"] = happySeederHRS["HAPPY SEEDER"];
          m["HAPPY SEEDER CHARGEABLE HRS"] = m["HAPPY SEEDER TOTAL HRS"];
          m["HAPPY SEEDER AVG"] =
            Number(m["HAPPY SEEDER COUNT"]) > 0
              ? Number(
                  Number(m["HAPPY SEEDER TOTAL HRS"]) /
                    Number(m["HAPPY SEEDER COUNT"])
                ).toFixed(2)
              : 0;
          m["HAPPY SEEDER UTILIZATION"] =
            Number(m["HAPPY SEEDER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["HAPPY SEEDER AVG"]) > 30 &&
                Number(m["HAPPY SEEDER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["HAPPY SEEDER AVG"]) < 31 &&
                Number(m["HAPPY SEEDER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < happySeederFreeHRSSummary.length; h++) {
      let happySeederHRS = happySeederFreeHRSSummary[h];
      finalSummary
        .filter((f) => f.id === happySeederHRS.id)
        .map((m) => {
          m["HAPPY SEEDER FREE HRS"] = happySeederHRS["HAPPY SEEDER"];
          m["HAPPY SEEDER CHARGEABLE HRS"] =
            Number(m["HAPPY SEEDER TOTAL HRS"]) -
            Number(m["HAPPY SEEDER FREE HRS"]);
        });
    }

    let superSocieties = [];
    for (let j = 0; j < superSeederSocieties.length; j++) {
      const superSeederSociety = superSeederSocieties[j];
      superSocieties.push(superSeederSociety.id);
    }
    // console.log("superSocieties", JSON.stringify(superSocieties));

    let superSeederHrsSummary =
      await this.props.getSocieytWiseSummaryForSocieties(
        superSocieties,
        "SUPERSEEDER",
        startDate,
        endDate
      );
    let superSeederFreeHrsSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        superSocieties,
        "SUPERSEEDER",
        startDate,
        endDate
      );
    // console.log("superSeederHrsSummary", JSON.stringify(superSeederHrsSummary));
    for (let h = 0; h < superSeederHrsSummary.length; h++) {
      let superSeederHrs = superSeederHrsSummary[h];

      superSeederSocieties
        .filter((f) => f.id === superSeederHrs.id)
        .map((m) => {
          m["SUPER SEEDER HRS"] = superSeederHrs["SUPER SEEDER"];
          m["SUPER SEEDER AVG"] =
            Number(m["SUPER SEEDER COUNT"]) > 0
              ? Number(
                  Number(m["SUPER SEEDER HRS"]) /
                    Number(m["SUPER SEEDER COUNT"])
                ).toFixed(2)
              : 0;
          m["SUPER SEEDER UTILIZATION"] =
            Number(m["SUPER SEEDER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["SUPER SEEDER AVG"]) > 30 &&
                Number(m["SUPER SEEDER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["SUPER SEEDER AVG"]) < 31 &&
                Number(m["SUPER SEEDER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === superSeederHrs.id)
        .map((m) => {
          m["SUPER SEEDER TOTAL HRS"] = superSeederHrs["SUPER SEEDER"];
          m["SUPER SEEDER CHARGEABLE HRS"] = m["SUPER SEEDER TOTAL HRS"];
          m["SUPER SEEDER AVG"] =
            Number(m["SUPER SEEDER COUNT"]) > 0
              ? Number(
                  Number(m["SUPER SEEDER TOTAL HRS"]) /
                    Number(m["SUPER SEEDER COUNT"])
                ).toFixed(2)
              : 0;
          m["SUPER SEEDER UTILIZATION"] =
            Number(m["SUPER SEEDER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["SUPER SEEDER AVG"]) > 30 &&
                Number(m["SUPER SEEDER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["SUPER SEEDER AVG"]) < 31 &&
                Number(m["SUPER SEEDER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < superSeederFreeHrsSummary.length; h++) {
      let superSeederHrs = superSeederFreeHrsSummary[h];

      finalSummary
        .filter((f) => f.id === superSeederHrs.id)
        .map((m) => {
          m["SUPER SEEDER FREE HRS"] = superSeederHrs["SUPER SEEDER"];
          m["SUPER SEEDER CHARGEABLE HRS"] =
            Number(m["SUPER SEEDER TOTAL HRS"]) -
            Number(m["SUPER SEEDER FREE HRS"]);
        });
    }
    // console.log("superSeederSocieties", JSON.stringify(superSeederSocieties));

    // let superSMSList = [];
    let surfaceSeederList = [];
    for (let j = 0; j < surfaceSeederSocieties.length; j++) {
      const surfaceSeederSociety = surfaceSeederSocieties[j];
      surfaceSeederList.push(surfaceSeederSociety.id);
    }

    // let superSMSHrsSummary = []
    let surfaceSeederHrsSummary =
      await this.props.getSocieytWiseSummaryForSocieties(
        surfaceSeederList,
        "SURFACESEEDER",
        startDate,
        endDate
      );
    // let superSMSFreeHrsSummary = []
    let surfaceSeederFreeHrsSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        surfaceSeederList,
        "SURFACESEEDER",
        startDate,
        endDate
      );
    // console.log("superSMSHrsSummary", JSON.stringify(superSMSHrsSummary));
    for (let h = 0; h < surfaceSeederHrsSummary.length; h++) {
      // let superSMSHrs = 0;
      let surfaceSeederHrs = surfaceSeederHrsSummary[h];

      surfaceSeederSocieties
        .filter((f) => f.id === surfaceSeederHrs.id)
        .map((m) => {
          m["SURFACE SEEDER HRS"] = surfaceSeederHrs["SURFACE SEEDER"];
          m["SURFACE SEEDER AVG"] =
            Number(m["SURFACE SEEDER COUNT"]) > 0
              ? Number(
                  Number(m["SURFACE SEEDER HRS"]) /
                    Number(m["SURFACE SEEDER COUNT"])
                ).toFixed(2)
              : 0;
          m["SURFACE SEEDER UTILIZATION"] =
            Number(m["SURFACE SEEDER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["SURFACE SEEDER AVG"]) > 30 &&
                Number(m["SURFACE SEEDER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["SURFACE SEEDER AVG"]) < 31 &&
                Number(m["SURFACE SEEDER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === surfaceSeederHrs.id)
        .map((m) => {
          m["SURFACE SEEDER TOTAL HRS"] = surfaceSeederHrs["SURFACE SEEDER"];
          m["SURFACE SEEDER CHARGEABLE HRS"] = m["SURFACE SEEDER TOTAL HRS"];
          m["SURFACE SEEDER AVG"] =
            Number(m["SURFACE SEEDER COUNT"]) > 0
              ? Number(
                  Number(m["SURFACE SEEDER TOTAL HRS"]) /
                    Number(m["SURFACE SEEDER COUNT"])
                ).toFixed(2)
              : 0;
          m["SURFACE SEEDER UTILIZATION"] =
            Number(m["SURFACE SEEDER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["SURFACE SEEDER AVG"]) > 30 &&
                Number(m["SURFACE SEEDER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["SURFACE SEEDER AVG"]) < 31 &&
                Number(m["SURFACE SEEDER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < surfaceSeederFreeHrsSummary.length; h++) {
      let surfaceSeederHrs = surfaceSeederFreeHrsSummary[h];
      finalSummary
        .filter((f) => f.id === surfaceSeederHrs.id)
        .map((m) => {
          m["SURFACE SEEDER FREE HRS"] = surfaceSeederHrs["SURFACE SEEDER"];
          m["SURFACE SEEDER CHARGEABLE HRS"] = Number(
            Number(m["SURFACE SEEDER TOTAL HRS"]) -
              Number(m["SURFACE SEEDER FREE HRS"])
          ).toFixed(2);
        });
    }
    // console.log("surfaceSeederSocieties", JSON.stringify(surfaceSeederSocieties));

    let rmbPloughList = [];
    for (let j = 0; j < rmbPloughSocieties.length; j++) {
      const rmbPloughSociety = rmbPloughSocieties[j];
      rmbPloughList.push(rmbPloughSociety.id);
    }

    let rmbPloughSummary = await this.props.getSocieytWiseSummaryForSocieties(
      rmbPloughList,
      "RMBPLOUGH",
      startDate,
      endDate
    );
    let rmbPloughFreeHrsSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        rmbPloughList,
        "RMBPLOUGH",
        startDate,
        endDate
      );
    // console.log("rmbPloughSummary", JSON.stringify(rmbPloughSummary));
    for (let h = 0; h < rmbPloughSummary.length; h++) {
      let rmbPloughHrs = rmbPloughSummary[h];

      rmbPloughSocieties
        .filter((f) => f.id === rmbPloughHrs.id)
        .map((m) => {
          m["RMB PLOUGH HRS"] = rmbPloughHrs["RMB PLOUGH"];
          m["RMB PLOUGH AVG"] =
            Number(m["RMB PLOUGH COUNT"]) > 0
              ? Number(
                  Number(m["RMB PLOUGH HRS"]) / Number(m["RMB PLOUGH COUNT"])
                ).toFixed(2)
              : 0;
          m["RMB PLOUGH UTILIZATION"] =
            Number(m["RMB PLOUGH AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["RMB PLOUGH AVG"]) > 30 &&
                Number(m["RMB PLOUGH AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["RMB PLOUGH AVG"]) < 31 &&
                Number(m["RMB PLOUGH AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === rmbPloughHrs.id)
        .map((m) => {
          m["RMB PLOUGH TOTAL HRS"] = rmbPloughHrs["RMB PLOUGH"];
          m["RMB PLOUGH CHARGEABLE HRS"] = m["RMB PLOUGH TOTAL HRS"];
          m["RMB PLOUGH AVG"] =
            Number(m["RMB PLOUGH COUNT"]) > 0
              ? Number(
                  Number(m["RMB PLOUGH TOTAL HRS"]) /
                    Number(m["RMB PLOUGH COUNT"])
                ).toFixed(2)
              : 0;
          m["RMB PLOUGH UTILIZATION"] =
            Number(m["RMB PLOUGH AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["RMB PLOUGH AVG"]) > 30 &&
                Number(m["RMB PLOUGH AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["RMB PLOUGH AVG"]) < 31 &&
                Number(m["RMB PLOUGH AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < rmbPloughFreeHrsSummary.length; h++) {
      let rmbPloughHrs = rmbPloughFreeHrsSummary[h];
      finalSummary
        .filter((f) => f.id === rmbPloughHrs.id)
        .map((m) => {
          m["RMB PLOUGH FREE HRS"] = rmbPloughHrs["RMB PLOUGH"];
          m["RMB PLOUGH CHARGEABLE HRS"] = Number(
            Number(m["RMB PLOUGH TOTAL HRS"]) - Number(m["RMB PLOUGH FREE HRS"])
          ).toFixed(2);
        });
    }

    // console.log("rmbPloughSocieties", JSON.stringify(rmbPloughSocieties));

    let rotavatorList = [];
    for (let j = 0; j < rotavatorSocieties.length; j++) {
      const rotavatorSociety = rotavatorSocieties[j];
      rotavatorList.push(rotavatorSociety.id);
    }

    let rotavatorSummary = await this.props.getSocieytWiseSummaryForSocieties(
      rotavatorList,
      "ROTAVATOR",
      startDate,
      endDate
    );
    let rotavatorFreeHRSSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        rotavatorList,
        "ROTAVATOR",
        startDate,
        endDate
      );
    // console.log("rotavatorSummary", JSON.stringify(rotavatorSummary));
    for (let h = 0; h < rotavatorSummary.length; h++) {
      let rotavatorHrs = rotavatorSummary[h];

      rotavatorSocieties
        .filter((f) => f.id === rotavatorHrs.id)
        .map((m) => {
          m["ROTAVATOR HRS"] = rotavatorHrs["ROTAVATOR"];
          m["ROTAVATOR AVG"] =
            Number(m["ROTAVATOR COUNT"]) > 0
              ? Number(
                  Number(m["ROTAVATOR HRS"]) / Number(m["ROTAVATOR COUNT"])
                ).toFixed(2)
              : 0;
          m["ROTAVATOR UTILIZATION"] =
            Number(m["ROTAVATOR AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["ROTAVATOR AVG"]) > 30 &&
                Number(m["ROTAVATOR AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["ROTAVATOR AVG"]) < 31 &&
                Number(m["ROTAVATOR AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === rotavatorHrs.id)
        .map((m) => {
          m["ROTAVATOR TOTAL HRS"] = rotavatorHrs["ROTAVATOR"];
          m["ROTAVATOR CHARGEABLE HRS"] = m["ROTAVATOR TOTAL HRS"];
          m["ROTAVATOR AVG"] =
            Number(m["ROTAVATOR COUNT"]) > 0
              ? Number(
                  Number(m["ROTAVATOR TOTAL HRS"]) /
                    Number(m["ROTAVATOR COUNT"])
                ).toFixed(2)
              : 0;
          m["ROTAVATOR UTILIZATION"] =
            Number(m["ROTAVATOR AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["ROTAVATOR AVG"]) > 30 &&
                Number(m["ROTAVATOR AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["ROTAVATOR AVG"]) < 31 &&
                Number(m["ROTAVATOR AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }
    // console.log("rotavatorSocieties", JSON.stringify(rotavatorSocieties));

    for (let h = 0; h < rotavatorFreeHRSSummary.length; h++) {
      let rotavatorHrs = rotavatorFreeHRSSummary[h];

      finalSummary
        .filter((f) => f.id === rotavatorHrs.id)
        .map((m) => {
          m["ROTAVATOR FREE HRS"] = rotavatorHrs["ROTAVATOR"];
          m["ROTAVATOR CHARGEABLE HRS"] = Number(
            Number(m["ROTAVATOR TOTAL HRS"]) - Number(m["ROTAVATOR FREE HRS"])
          ).toFixed(2);
        });
    }

    let ztdList = [];
    for (let j = 0; j < ztdSocieties.length; j++) {
      const ztdSociety = ztdSocieties[j];
      ztdList.push(ztdSociety.id);
    }

    let ztdSummary = await this.props.getSocieytWiseSummaryForSocieties(
      ztdList,
      "ZEROTILLDRILL",
      startDate,
      endDate
    );
    let ztdFreeHRSSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        ztdList,
        "ZEROTILLDRILL",
        startDate,
        endDate
      );
    // console.log("ztdSummary", JSON.stringify(ztdSummary));
    for (let h = 0; h < ztdSummary.length; h++) {
      let ztdHrs = ztdSummary[h];

      ztdSocieties
        .filter((f) => f.id === ztdHrs.id)
        .map((m) => {
          m["ZERO TILL DRILL HRS"] = ztdHrs["ZERO TILL DRILL"];
          m["ZERO TILL DRILL AVG"] =
            Number(m["ZERO TILL DRILL COUNT"]) > 0
              ? Number(
                  Number(m["ZERO TILL DRILL HRS"]) /
                    Number(m["ZERO TILL DRILL COUNT"])
                ).toFixed(2)
              : 0;
          m["ZERO TILL DRILL UTILIZATION"] =
            Number(m["ZERO TILL DRILL AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["ZERO TILL DRILL AVG"]) > 30 &&
                Number(m["ZERO TILL DRILL AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["ZERO TILL DRILL AVG"]) < 31 &&
                Number(m["ZERO TILL DRILL AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === ztdHrs.id)
        .map((m) => {
          m["ZERO TILL DRILL TOTAL HRS"] = ztdHrs["ZERO TILL DRILL"];
          m["ZERO TILL DRILL CHARGEABLE HRS"] = m["ZERO TILL DRILL TOTAL HRS"];
          m["ZERO TILL DRILL AVG"] =
            Number(m["ZERO TILL DRILL COUNT"]) > 0
              ? Number(
                  Number(m["ZERO TILL DRILL TOTAL HRS"]) /
                    Number(m["ZERO TILL DRILL COUNT"])
                ).toFixed(2)
              : 0;
          m["ZERO TILL DRILL UTILIZATION"] =
            Number(m["ZERO TILL DRILL AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["ZERO TILL DRILL AVG"]) > 30 &&
                Number(m["ZERO TILL DRILL AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["ZERO TILL DRILL AVG"]) < 31 &&
                Number(m["ZERO TILL DRILL AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < ztdFreeHRSSummary.length; h++) {
      let ztdHrs = ztdFreeHRSSummary[h];

      finalSummary
        .filter((f) => f.id === ztdHrs.id)
        .map((m) => {
          m["ZERO TILL DRILL FREE HRS"] = ztdHrs["ZERO TILL DRILL"];
          m["ZERO TILL DRILL CHARGEABLE HRS"] = Number(
            Number(m["ZERO TILL DRILL TOTAL HRS"]) -
              Number(m["ZERO TILL DRILL FREE HRS"])
          ).toFixed(2);
        });
    }
    // console.log("ztdSocieties", JSON.stringify(ztdSocieties));

    let chopperList = [];
    for (let j = 0; j < chopperSocieties.length; j++) {
      const chopperSociety = chopperSocieties[j];
      chopperList.push(chopperSociety.id);
    }

    let chopperSummary = await this.props.getSocieytWiseSummaryForSocieties(
      chopperList,
      "PADDYSTRAWCHOPPER",
      startDate,
      endDate
    );
    let chopperFreeHRSSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        chopperList,
        "PADDYSTRAWCHOPPER",
        startDate,
        endDate
      );
    // console.log("chopperSummary", JSON.stringify(chopperSummary));
    for (let h = 0; h < chopperSummary.length; h++) {
      let chopperHrs = chopperSummary[h];

      chopperSocieties
        .filter((f) => f.id === chopperHrs.id)
        .map((m) => {
          m["CHOPPER HRS"] = chopperHrs["CHOPPER"];
          m["CHOPPER AVG"] =
            Number(m["CHOPPER COUNT"]) > 0
              ? Number(
                  Number(m["CHOPPER HRS"]) / Number(m["CHOPPER COUNT"])
                ).toFixed(2)
              : 0;
          m["CHOPPER UTILIZATION"] =
            Number(m["CHOPPER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["CHOPPER AVG"]) > 30 && Number(m["CHOPPER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["CHOPPER AVG"]) < 31 && Number(m["CHOPPER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === chopperHrs.id)
        .map((m) => {
          m["CHOPPER TOTAL HRS"] = chopperHrs["CHOPPER"];
          m["CHOPPER CHARGEABLE HRS"] = m["CHOPPER TOTAL HRS"];
          m["CHOPPER AVG"] =
            Number(m["CHOPPER COUNT"]) > 0
              ? Number(
                  Number(m["CHOPPER TOTAL HRS"]) / Number(m["CHOPPER COUNT"])
                ).toFixed(2)
              : 0;
          m["CHOPPER UTILIZATION"] =
            Number(m["CHOPPER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["CHOPPER AVG"]) > 30 && Number(m["CHOPPER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["CHOPPER AVG"]) < 31 && Number(m["CHOPPER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < chopperFreeHRSSummary.length; h++) {
      let chopperHrs = chopperFreeHRSSummary[h];

      finalSummary
        .filter((f) => f.id === chopperHrs.id)
        .map((m) => {
          m["CHOPPER FREE HRS"] = chopperHrs["CHOPPER"];
          m["CHOPPER CHARGEABLE HRS"] = Number(
            Number(m["CHOPPER TOTAL HRS"]) - Number(m["CHOPPER FREE HRS"])
          ).toFixed(2);
        });
    }
    // console.log("chopperSocieties", JSON.stringify(chopperSocieties));

    let mulcherList = [];
    for (let j = 0; j < mulcherSocieties.length; j++) {
      const mulcherSociety = mulcherSocieties[j];
      mulcherList.push(mulcherSociety.id);
    }

    let mulcherSummary = await this.props.getSocieytWiseSummaryForSocieties(
      mulcherList,
      "MULCHER",
      startDate,
      endDate
    );
    let mulcherFreeHRSSummary =
      await this.props.getSocieytWiseFreeHrsSummaryForSocieties(
        mulcherList,
        "MULCHER",
        startDate,
        endDate
      );
    // console.log("mulcherSummary", JSON.stringify(mulcherSummary));
    for (let h = 0; h < mulcherSummary.length; h++) {
      let mulcherHrs = mulcherSummary[h];

      mulcherSocieties
        .filter((f) => f.id === mulcherHrs.id)
        .map((m) => {
          m["MULCHER HRS"] = mulcherHrs["MULCHER"];
          m["MULCHER AVG"] =
            Number(m["MULCHER COUNT"]) > 0
              ? Number(
                  Number(m["MULCHER HRS"]) / Number(m["MULCHER COUNT"])
                ).toFixed(2)
              : 0;
          m["MULCHER UTILIZATION"] =
            Number(m["MULCHER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["MULCHER AVG"]) > 30 && Number(m["MULCHER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["MULCHER AVG"]) < 31 && Number(m["MULCHER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });

      finalSummary
        .filter((f) => f.id === mulcherHrs.id)
        .map((m) => {
          m["MULCHER TOTAL HRS"] = mulcherHrs["MULCHER"];
          m["MULCHER CHARGEABLE HRS"] = m["MULCHER TOTAL HRS"];
          m["MULCHER AVG"] =
            Number(m["MULCHER COUNT"]) > 0
              ? Number(
                  Number(m["MULCHER TOTAL HRS"]) / Number(m["MULCHER COUNT"])
                ).toFixed(2)
              : 0;
          m["MULCHER UTILIZATION"] =
            Number(m["MULCHER AVG"]) > 51
              ? "Above 50 Hrs"
              : Number(m["MULCHER AVG"]) > 30 && Number(m["MULCHER AVG"]) < 50
              ? "30-50 Hrs"
              : Number(m["MULCHER AVG"]) < 31 && Number(m["MULCHER AVG"]) > 0
              ? "Upto 30 Hrs"
              : "0 Utilization";
        });
    }

    for (let h = 0; h < mulcherFreeHRSSummary.length; h++) {
      let mulcherHrs = mulcherFreeHRSSummary[h];

      finalSummary
        .filter((f) => f.id === mulcherHrs.id)
        .map((m) => {
          m["MULCHER FREE HRS"] = mulcherHrs["MULCHER"];
          m["MULCHER CHARGEABLE HRS"] = Number(
            Number(m["MULCHER TOTAL HRS"]) - Number(m["MULCHER FREE HRS"])
          ).toFixed(2);
        });
    }
    // console.log("finalSummary", JSON.stringify(finalSummary));

    this.setState({
      happySeederSocieties: happySeederSocieties,
      superSeederSocieties: superSeederSocieties,
      surfaceSeederSocieties: surfaceSeederSocieties,
      rmbPloughSocieties: rmbPloughSocieties,
      rotavatorSocieties: rotavatorSocieties,
      ztdSocieties: ztdSocieties,
      chopperSocieties: chopperSocieties,
      mulcherSocieties: mulcherSocieties,
      societyMachineSummary: finalSummary,
      loading: false,
    });
  }

  async onDistrictSelected(district, startDate, endDate) {
    this.setState({ loading: true });
    this.setState({ optionSelected: "onDistrictSelected" });
    let ars = await this.props.getARCircleWiseMachineCountForRCS(district);

    let arTotal = {
      "HAPPY SEEDER TOTAL COUNT": 0,
      "SUPER SEEDER TOTAL COUNT": 0,
      "SURFACE SEEDER TOTAL COUNT": 0,
      "RMB PLOUGH TOTAL COUNT": 0,
      "ROTAVATOR TOTAL COUNT": 0,
      "ZERO TILL DRILL TOTAL COUNT": 0,
      "CHOPPER TOTAL COUNT": 0,
      "MULCHER TOTAL COUNT": 0,

      "HAPPY SEEDER TOTAL UTILIZATION": 0,
      "SUPER SEEDER TOTAL UTILIZATION": 0,
      "SURFACE SEEDER TOTAL UTILIZATION": 0,
      "RMB PLOUGH TOTAL UTILIZATION": 0,
      "ROTAVATOR TOTAL UTILIZATION": 0,
      "ZERO TILL DRILL TOTAL UTILIZATION": 0,
      "CHOPPER TOTAL UTILIZATION": 0,
      "MULCHER TOTAL UTILIZATION": 0,

      "HAPPY SEEDER TOTAL AVG": 0,
      "SUPER SEEDER TOTAL AVG": 0,
      "SURFACE SEEDER TOTAL AVG": 0,
      "RMB PLOUGH TOTAL AVG": 0,
      "ROTAVATOR TOTAL AVG": 0,
      "ZERO TILL DRILL TOTAL AVG": 0,
      "CHOPPER TOTAL AVG": 0,
      "MULCHER TOTAL AVG": 0,

      "HAPPY SEEDER TOTAL PREV AVG": 0,
      "SUPER SEEDER TOTAL PREV AVG": 0,
      "SURFACE SEEDER TOTAL PREV AVG": 0,
      "RMB PLOUGH TOTAL PREV AVG": 0,
      "ROTAVATOR TOTAL PREV AVG": 0,
      "ZERO TILL DRILL TOTAL PREV AVG": 0,
      "CHOPPER TOTAL PREV AVG": 0,
      "MULCHER TOTAL PREV AVG": 0,
    };

    let finalARData = [];

    let arArray = [];
    //Pushing Machine Count
    for (let index = 0; index < ars.length; index++) {
      const ar = ars[index];
      let data = {
        name: ar.name,
        "HAPPY SEEDER COUNT": ar["HAPPY SEEDER"],
        "SUPER SEEDER COUNT": ar["SUPER SEEDER"],
        "SURFACE SEEDER COUNT": ar["SURFACE SEEDER"],
        "RMB PLOUGH COUNT": ar["RMB PLOUGH"],
        "ROTAVATOR COUNT": ar["ROTAVATOR"],
        "ZERO TILL DRILL COUNT": ar["ZERO TILL DRILL"],
        "CHOPPER COUNT": ar["CHOPPER"],
        "MULCHER COUNT": ar["MULCHER"],

        "HAPPY SEEDER HRS": 0,
        "SUPER SEEDER HRS": 0,
        "SURFACE SEEDER HRS": 0,
        "RMB PLOUGH HRS": 0,
        "ROTAVATOR HRS": 0,
        "ZERO TILL DRILL HRS": 0,
        "CHOPPER HRS": 0,
        "MULCHER HRS": 0,

        "HAPPY SEEDER CHARGEABLE HRS": 0,
        "SUPER SEEDER CHARGEABLE HRS": 0,
        "SURFACE SEEDER CHARGEABLE HRS": 0,
        "RMB PLOUGH CHARGEABLE HRS": 0,
        "ROTAVATOR CHARGEABLE HRS": 0,
        "ZERO TILL DRILL CHARGEABLE HRS": 0,
        "CHOPPER CHARGEABLE HRS": 0,
        "MULCHER CHARGEABLE HRS": 0,

        "HAPPY SEEDER FREE HRS": 0,
        "SUPER SEEDER FREE HRS": 0,
        "SURFACE SEEDER FREE HRS": 0,
        "RMB PLOUGH FREE HRS": 0,
        "ROTAVATOR FREE HRS": 0,
        "ZERO TILL DRILL FREE HRS": 0,
        "CHOPPER FREE HRS": 0,
        "MULCHER FREE HRS": 0,

        "HAPPY SEEDER AVG": 0,
        "SUPER SEEDER AVG": 0,
        "SURFACE SEEDER AVG": 0,
        "RMB PLOUGH AVG": 0,
        "ROTAVATOR AVG": 0,
        "ZERO TILL DRILL AVG": 0,
        "CHOPPER AVG": 0,
        "MULCHER AVG": 0,

        "HAPPY SEEDER PREV AVG": 0,
        "SUPER SEEDER PREV AVG": 0,
        "SURFACE SEEDER PREV AVG": 0,
        "RMB PLOUGH PREV AVG": 0,
        "ROTAVATOR PREV AVG": 0,
        "ZERO TILL DRILL PREV AVG": 0,
        "CHOPPER PREV AVG": 0,
        "MULCHER PREV AVG": 0,
      };
      arArray.push(data.name);
      finalARData.push(data);
    }

    let arData = await this.props.getARWiseSummaryForRCS(
      arArray,
      startDate,
      endDate
    );
    let arFreeData = await this.props.getARWiseFreeSummaryForRCS(
      arArray,
      startDate,
      endDate
    );
    // console.log("arData", arData);

    //Pushing Chargeable Hrs
    for (let j = 0; j < arData.length; j++) {
      const data = arData[j];

      // console.log(data);
      finalARData
        .filter((f) => f.name === data.name)
        .map((m) => {
          m["HAPPY SEEDER HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER HRS"] = data["CHOPPER"];
          m["MULCHER HRS"] = data["MULCHER"];

          m["HAPPY SEEDER CHARGEABLE HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER CHARGEABLE HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER CHARGEABLE HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH CHARGEABLE HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR CHARGEABLE HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL CHARGEABLE HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER CHARGEABLE HRS"] = data["CHOPPER"];
          m["MULCHER CHARGEABLE HRS"] = data["MULCHER"];
        });
    }

    //Pushing Free Hrs
    for (let j = 0; j < arFreeData.length; j++) {
      const data = arFreeData[j];

      // console.log(data);
      finalARData
        .filter((f) => f.name === data.name)
        .map((m) => {
          m["HAPPY SEEDER FREE HRS"] = data["HAPPY SEEDER"];
          m["SUPER SEEDER FREE HRS"] = data["SUPER SEEDER"];
          m["SURFACE SEEDER FREE HRS"] = data["SURFACE SEEDER"];
          m["RMB PLOUGH FREE HRS"] = data["RMB PLOUGH"];
          m["ROTAVATOR FREE HRS"] = data["ROTAVATOR"];
          m["ZERO TILL DRILL FREE HRS"] = data["ZERO TILL DRILL"];
          m["CHOPPER FREE HRS"] = data["CHOPPER"];
          m["MULCHER FREE HRS"] = data["MULCHER"];

          m["HAPPY SEEDER HRS"] =
            Number(m["HAPPY SEEDER HRS"]) + Number(data["HAPPY SEEDER"]);
          m["SUPER SEEDER HRS"] =
            Number(m["SUPER SEEDER HRS"]) + Number(data["SUPER SEEDER"]);
          m["SURFACE SEEDER HRS"] =
            Number(m["SURFACE SEEDER HRS"]) + Number(data["SURFACE SEEDER"]);
          m["RMB PLOUGH HRS"] =
            Number(m["RMB PLOUGH HRS"]) + Number(data["RMB PLOUGH"]);
          m["ROTAVATOR HRS"] =
            Number(m["ROTAVATOR HRS"]) + Number(data["ROTAVATOR"]);
          m["ZERO TILL DRILL HRS"] =
            Number(m["ZERO TILL DRILL HRS"]) + Number(data["ZERO TILL DRILL"]);
          m["CHOPPER HRS"] = Number(m["CHOPPER HRS"]) + Number(data["CHOPPER"]);
          m["MULCHER HRS"] = Number(m["MULCHER HRS"]) + Number(data["MULCHER"]);
        });
    }

    let arDataPrev = [];
    let arFreeDataPrev = [];

    // if(this.state.selectedYearIndex==="0"){
    ///
    let previousPeriodIndex = Number(this.state.selectedYearIndex) + 2;
    if (previousPeriodIndex < this.state.years.length) {
      let prevStartDate = new Date(
        this.state.years[Number(this.state.selectedYearIndex) + 2].StartDate
      )
        .toISOString()
        .split("T")[0];
      let prevEndDate = new Date(
        this.state.years[Number(this.state.selectedYearIndex) + 2].EndDate
      )
        .toISOString()
        .split("T")[0];
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      console.log("prevStartDate", prevStartDate);
      console.log("prevEndDate", prevEndDate);

      arDataPrev = await this.props.getARWiseSummaryForRCS(
        arArray,
        prevStartDate,
        prevEndDate
      );
      arFreeDataPrev = await this.props.getARWiseFreeSummaryForRCS(
        arArray,
        prevStartDate,
        prevEndDate
      );
    }

    let happySeederTotalPrevHRS = 0;
    let supperSeederTotalPrevHRS = 0;
    let supperSMSTotalPrevHRS = 0;
    let rmbTotalPrevHRS = 0;
    let rotavatorTotalPrevHRS = 0;
    let zeroTotalPrevHRS = 0;
    let chopperTotalPrevHRS = 0;
    let mulcherTotalPrevHRS = 0;

    for (let w = 0; w < finalARData.length; w++) {
      const m = finalARData[w];
      m["HAPPY SEEDER AVG"] =
        Number(m["HAPPY SEEDER COUNT"]) > 0
          ? Number(
              Number(m["HAPPY SEEDER HRS"]) / Number(m["HAPPY SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["SUPER SEEDER AVG"] =
        Number(m["SUPER SEEDER COUNT"]) > 0
          ? Number(
              Number(m["SUPER SEEDER HRS"]) / Number(m["SUPER SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["SURFACE SEEDER AVG"] =
        Number(m["SURFACE SEEDER COUNT"]) > 0
          ? Number(
              Number(m["SURFACE SEEDER HRS"]) /
                Number(m["SURFACE SEEDER COUNT"])
            ).toFixed(2)
          : 0;
      m["RMB PLOUGH AVG"] =
        Number(m["RMB PLOUGH COUNT"]) > 0
          ? Number(
              Number(m["RMB PLOUGH HRS"]) / Number(m["RMB PLOUGH COUNT"])
            ).toFixed(2)
          : 0;
      m["ROTAVATOR AVG"] =
        Number(m["ROTAVATOR COUNT"]) > 0
          ? Number(
              Number(m["ROTAVATOR HRS"]) / Number(m["ROTAVATOR COUNT"])
            ).toFixed(2)
          : 0;
      m["ZERO TILL DRILL AVG"] =
        Number(m["ZERO TILL DRILL COUNT"]) > 0
          ? Number(
              Number(m["ZERO TILL DRILL HRS"]) /
                Number(m["ZERO TILL DRILL COUNT"])
            ).toFixed(2)
          : 0;
      m["CHOPPER AVG"] =
        Number(m["CHOPPER COUNT"]) > 0
          ? Number(
              Number(m["CHOPPER HRS"]) / Number(m["CHOPPER COUNT"])
            ).toFixed(2)
          : 0;
      m["MULCHER AVG"] =
        Number(m["MULCHER COUNT"]) > 0
          ? Number(
              Number(m["MULCHER HRS"]) / Number(m["MULCHER COUNT"])
            ).toFixed(2)
          : 0;

      let result1 = arDataPrev.filter((f) => f.name === m.name);
      let result2 = arFreeDataPrev.filter((f) => f.name === m.name);

      if (Number(m["HAPPY SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["HAPPY SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["HAPPY SEEDER"]);
        }
        m["HAPPY SEEDER PREV AVG"] = (
          hrs / Number(m["HAPPY SEEDER COUNT"])
        ).toFixed(2);
        happySeederTotalPrevHRS = Number(happySeederTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["SUPER SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["SUPER SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["SUPER SEEDER"]);
        }
        m["SUPER SEEDER PREV AVG"] = (
          hrs / Number(m["SUPER SEEDER COUNT"])
        ).toFixed(2);
        supperSeederTotalPrevHRS =
          Number(supperSeederTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["SURFACE SEEDER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["SURFACE SEEDER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["SURFACE SEEDER"]);
        }
        m["SURFACE SEEDER PREV AVG"] = (
          hrs / Number(m["SURFACE SEEDER COUNT"])
        ).toFixed(2);
        supperSMSTotalPrevHRS = Number(supperSMSTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["RMB PLOUGH COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["RMB PLOUGH"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["RMB PLOUGH"]);
        }
        m["RMB PLOUGH PREV AVG"] = (
          hrs / Number(m["RMB PLOUGH COUNT"])
        ).toFixed(2);
        rmbTotalPrevHRS = Number(rmbTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["ROTAVATOR COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["ROTAVATOR"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["ROTAVATOR"]);
        }
        m["ROTAVATOR PREV AVG"] = (hrs / Number(m["ROTAVATOR COUNT"])).toFixed(
          2
        );
        rotavatorTotalPrevHRS = Number(rotavatorTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["ZERO TILL DRILL COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["ZERO TILL DRILL"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["ZERO TILL DRILL"]);
        }
        m["ZERO TILL DRILL PREV AVG"] = (
          hrs / Number(m["ZERO TILL DRILL COUNT"])
        ).toFixed(2);
        zeroTotalPrevHRS = Number(zeroTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["CHOPPER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["CHOPPER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["CHOPPER"]);
        }
        m["CHOPPER PREV AVG"] = (hrs / Number(m["CHOPPER COUNT"])).toFixed(2);
        chopperTotalPrevHRS = Number(chopperTotalPrevHRS) + Number(hrs);
      }
      if (Number(m["MULCHER COUNT"]) > 0) {
        let hrs = 0;
        if (result1.length > 0) {
          hrs = hrs + Number(result1[0]["MULCHER"]);
        }
        if (result2.length > 0) {
          hrs = hrs + Number(result2[0]["MULCHER"]);
        }
        m["MULCHER PREV AVG"] = (hrs / Number(m["MULCHER COUNT"])).toFixed(2);
        mulcherTotalPrevHRS = Number(mulcherTotalPrevHRS) + Number(hrs);
      }

      arTotal["HAPPY SEEDER TOTAL COUNT"] =
        Number(arTotal["HAPPY SEEDER TOTAL COUNT"]) +
        Number(m["HAPPY SEEDER COUNT"]);
      arTotal["SUPER SEEDER TOTAL COUNT"] =
        Number(arTotal["SUPER SEEDER TOTAL COUNT"]) +
        Number(m["SUPER SEEDER COUNT"]);
      arTotal["SURFACE SEEDER TOTAL COUNT"] =
        Number(arTotal["SURFACE SEEDER TOTAL COUNT"]) +
        Number(m["SURFACE SEEDER COUNT"]);
      arTotal["RMB PLOUGH TOTAL COUNT"] =
        Number(arTotal["RMB PLOUGH TOTAL COUNT"]) +
        Number(m["RMB PLOUGH COUNT"]);
      arTotal["ROTAVATOR TOTAL COUNT"] =
        Number(arTotal["ROTAVATOR TOTAL COUNT"]) + Number(m["ROTAVATOR COUNT"]);
      arTotal["ZERO TILL DRILL TOTAL COUNT"] =
        Number(arTotal["ZERO TILL DRILL TOTAL COUNT"]) +
        Number(m["ZERO TILL DRILL COUNT"]);
      arTotal["CHOPPER TOTAL COUNT"] =
        Number(arTotal["CHOPPER TOTAL COUNT"]) + Number(m["CHOPPER COUNT"]);
      arTotal["MULCHER TOTAL COUNT"] =
        Number(arTotal["MULCHER TOTAL COUNT"]) + Number(m["MULCHER COUNT"]);

      arTotal["HAPPY SEEDER TOTAL UTILIZATION"] =
        Number(arTotal["HAPPY SEEDER TOTAL UTILIZATION"]) +
        Number(m["HAPPY SEEDER HRS"]);
      arTotal["SUPER SEEDER TOTAL UTILIZATION"] =
        Number(arTotal["SUPER SEEDER TOTAL UTILIZATION"]) +
        Number(m["SUPER SEEDER HRS"]);
      arTotal["SURFACE SEEDER TOTAL UTILIZATION"] =
        Number(arTotal["SURFACE SEEDER TOTAL UTILIZATION"]) +
        Number(m["SURFACE SEEDER HRS"]);
      arTotal["RMB PLOUGH TOTAL UTILIZATION"] =
        Number(arTotal["RMB PLOUGH TOTAL UTILIZATION"]) +
        Number(m["RMB PLOUGH HRS"]);
      arTotal["ROTAVATOR TOTAL UTILIZATION"] =
        Number(arTotal["ROTAVATOR TOTAL UTILIZATION"]) +
        Number(m["ROTAVATOR HRS"]);
      arTotal["ZERO TILL DRILL TOTAL UTILIZATION"] =
        Number(arTotal["ZERO TILL DRILL TOTAL UTILIZATION"]) +
        Number(m["ZERO TILL DRILL HRS"]);
      arTotal["CHOPPER TOTAL UTILIZATION"] =
        Number(arTotal["CHOPPER TOTAL UTILIZATION"]) + Number(m["CHOPPER HRS"]);
      arTotal["MULCHER TOTAL UTILIZATION"] =
        Number(arTotal["MULCHER TOTAL UTILIZATION"]) + Number(m["MULCHER HRS"]);
    }

    arTotal["HAPPY SEEDER TOTAL AVG"] =
      Number(arTotal["HAPPY SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["HAPPY SEEDER TOTAL UTILIZATION"]) /
              Number(arTotal["HAPPY SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["SUPER SEEDER TOTAL AVG"] =
      Number(arTotal["SUPER SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["SUPER SEEDER TOTAL UTILIZATION"]) /
              arTotal["SUPER SEEDER TOTAL COUNT"]
          ).toFixed(2)
        : 0;
    arTotal["SURFACE SEEDER TOTAL AVG"] =
      Number(arTotal["SURFACE SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["SURFACE SEEDER TOTAL UTILIZATION"]) /
              Number(arTotal["SURFACE SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["RMB PLOUGH TOTAL AVG"] =
      Number(arTotal["RMB PLOUGH TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["RMB PLOUGH TOTAL UTILIZATION"]) /
              Number(arTotal["RMB PLOUGH TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["ROTAVATOR TOTAL AVG"] =
      Number(arTotal["ROTAVATOR TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["ROTAVATOR TOTAL UTILIZATION"]) /
              Number(arTotal["ROTAVATOR TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["ZERO TILL DRILL TOTAL AVG"] =
      Number(arTotal["ZERO TILL DRILL TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["ZERO TILL DRILL TOTAL UTILIZATION"]) /
              Number(arTotal["ZERO TILL DRILL TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["CHOPPER TOTAL AVG"] =
      Number(arTotal["CHOPPER TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["CHOPPER TOTAL UTILIZATION"]) /
              Number(arTotal["CHOPPER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["MULCHER TOTAL AVG"] =
      Number(arTotal["MULCHER TOTAL COUNT"]) > 0
        ? Number(
            Number(arTotal["MULCHER TOTAL UTILIZATION"]) /
              Number(arTotal["MULCHER TOTAL COUNT"])
          ).toFixed(2)
        : 0;

    arTotal["HAPPY SEEDER TOTAL PREV AVG"] =
      Number(arTotal["HAPPY SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(happySeederTotalPrevHRS) /
              Number(arTotal["HAPPY SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["SUPER SEEDER TOTAL PREV AVG"] =
      Number(arTotal["SUPER SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(supperSeederTotalPrevHRS) /
              arTotal["SUPER SEEDER TOTAL COUNT"]
          ).toFixed(2)
        : 0;
    arTotal["SURFACE SEEDER TOTAL PREV AVG"] =
      Number(arTotal["SURFACE SEEDER TOTAL COUNT"]) > 0
        ? Number(
            Number(supperSMSTotalPrevHRS) /
              Number(arTotal["SURFACE SEEDER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["RMB PLOUGH TOTAL PREV AVG"] =
      Number(arTotal["RMB PLOUGH TOTAL COUNT"]) > 0
        ? Number(
            Number(rmbTotalPrevHRS) / Number(arTotal["RMB PLOUGH TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["ROTAVATOR TOTAL PREV AVG"] =
      Number(arTotal["ROTAVATOR TOTAL COUNT"]) > 0
        ? Number(
            Number(rotavatorTotalPrevHRS) /
              Number(arTotal["ROTAVATOR TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["ZERO TILL DRILL TOTAL PREV AVG"] =
      Number(arTotal["ZERO TILL DRILL TOTAL COUNT"]) > 0
        ? Number(
            Number(zeroTotalPrevHRS) /
              Number(arTotal["ZERO TILL DRILL TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["CHOPPER TOTAL PREV AVG"] =
      Number(arTotal["CHOPPER TOTAL COUNT"]) > 0
        ? Number(
            Number(chopperTotalPrevHRS) / Number(arTotal["CHOPPER TOTAL COUNT"])
          ).toFixed(2)
        : 0;
    arTotal["MULCHER TOTAL PREV AVG"] =
      Number(arTotal["MULCHER TOTAL COUNT"]) > 0
        ? Number(
            Number(mulcherTotalPrevHRS) / Number(arTotal["MULCHER TOTAL COUNT"])
          ).toFixed(2)
        : 0;

    console.log("finalARData", finalARData);
    this.setState({
      loading: false,
      selectedDistrict: district,
      ars: finalARData,
      arTotal: arTotal,
    });
  }

  async onExportTransactions(startDate, endDate) {
    let result = await this.props.getAllDistrictTransactions(
      this.state.districtArray,
      startDate,
      endDate,
      ""
    );
    let scrollID = result.scrollID;
    let transactions = result.searchResult;

    // console.log("transactions", JSON.stringify(transactions));

    for (let index = 1; index < result.totalItemsCount / 2000; index++) {
      result = await this.props.getAllDistrictTransactions(
        [],
        "",
        "",
        scrollID
      );
      result.searchResult.forEach((element) => {
        transactions.push(element);
      });
    }

    let finalTransactions = [];

    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];

      let obj = {
        Division: transaction.Division,
        District: transaction.District,
        "AR Circle": transaction.AR,
        Village: transaction.Village,
        Society: transaction.Society,
        // "SocietyID": transaction.SocietyID,
        FarmerMobileNo: transaction.FarmerMobileNo,
        FarmerName: transaction.FarmerName,
        FatherName: transaction.FatherName,
        FarmerProfile: transaction.FarmerProfile,
        MachineCode: transaction.MachineCode,
        Machine: transaction.Machine,
        MachineHrs: transaction.MachineHrs,
        ChargesPerHrs: transaction.ChargesPerHrs,
        MachineRental: transaction.MachineRental,
        TractorRequired: transaction.TractorRequired,
        TractorRental: transaction.TractorRental,
        TotalRental: transaction.TotalRental,
        "Transaction Date": moment(
          new Date(transaction.TransactionDate)
        ).format("DD-MMM-yyyy"),
      };

      finalTransactions.push(obj);
    }

    // console.log("finalTransactions", JSON.stringify(finalTransactions));

    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Transactions.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  objToCSV(obj) {
    const csvRows = [];
    const headers = Object.keys(obj[0]);
    csvRows.push(headers.join(","));

    for (const row of obj) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  }

  onExportHappySeederSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.happySeederSocieties.length; i++) {
        const transaction = this.state.happySeederSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "HAPPY SEEDER UTILIZATION": transaction["HAPPY SEEDER UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.happySeederSocieties.length; i++) {
        const transaction = this.state.happySeederSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "HAPPY SEEDER UTILIZATION": transaction["HAPPY SEEDER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.happySeederSocieties.length; i++) {
        const transaction = this.state.happySeederSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "HAPPY SEEDER UTILIZATION": transaction["HAPPY SEEDER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    console.log("finalTransactions", JSON.stringify(finalTransactions));
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "HappySeeder.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportSuperSeederSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.superSeederSocieties.length; i++) {
        const transaction = this.state.superSeederSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "SUPER SEEDER UTILIZATION": transaction["SUPER SEEDER UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.superSeederSocieties.length; i++) {
        const transaction = this.state.superSeederSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "SUPER SEEDER UTILIZATION": transaction["SUPER SEEDER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.superSeederSocieties.length; i++) {
        const transaction = this.state.superSeederSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "SUPER SEEDER UTILIZATION": transaction["SUPER SEEDER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SuperSeeder.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportSurfaceSeederSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.surfaceSeederSocieties.length; i++) {
        const transaction = this.state.surfaceSeederSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "SURFACE SEEDER UTILIZATION":
            transaction["SURFACE SEEDER UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.surfaceSeederSocieties.length; i++) {
        const transaction = this.state.surfaceSeederSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "SURFACE SEEDER UTILIZATION":
              transaction["SURFACE SEEDER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.surfaceSeederSocieties.length; i++) {
        const transaction = this.state.surfaceSeederSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "SURFACE SEEDER UTILIZATION":
              transaction["SURFACE SEEDER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SURFACESEEDER.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportRMBPloughSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.rmbPloughSocieties.length; i++) {
        const transaction = this.state.rmbPloughSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "RMB PLOUGH UTILIZATION": transaction["RMB PLOUGH UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.rmbPloughSocieties.length; i++) {
        const transaction = this.state.rmbPloughSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "RMB PLOUGH UTILIZATION": transaction["RMB PLOUGH UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.rmbPloughSocieties.length; i++) {
        const transaction = this.state.rmbPloughSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "RMB PLOUGH UTILIZATION": transaction["RMB PLOUGH UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "RMBPlough.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportRotavatorSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.rotavatorSocieties.length; i++) {
        const transaction = this.state.rotavatorSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "ROTAVATOR UTILIZATION": transaction["ROTAVATOR UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.rotavatorSocieties.length; i++) {
        const transaction = this.state.rotavatorSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "ROTAVATOR UTILIZATION": transaction["ROTAVATOR UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.rotavatorSocieties.length; i++) {
        const transaction = this.state.rotavatorSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "ROTAVATOR UTILIZATION": transaction["ROTAVATOR UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Rotavator.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportZTDSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.ztdSocieties.length; i++) {
        const transaction = this.state.ztdSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "ZERO TILL DRILL UTILIZATION":
            transaction["ZERO TILL DRILL UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.ztdSocieties.length; i++) {
        const transaction = this.state.ztdSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "ZERO TILL DRILL UTILIZATION":
              transaction["ZERO TILL DRILL UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.ztdSocieties.length; i++) {
        const transaction = this.state.ztdSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "ZERO TILL DRILL UTILIZATION":
              transaction["ZERO TILL DRILL UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "ZTD.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportChopperSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.chopperSocieties.length; i++) {
        const transaction = this.state.chopperSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "CHOPPER UTILIZATION": transaction["CHOPPER UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.chopperSocieties.length; i++) {
        const transaction = this.state.chopperSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "CHOPPER UTILIZATION": transaction["CHOPPER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.chopperSocieties.length; i++) {
        const transaction = this.state.chopperSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "CHOPPER UTILIZATION": transaction["CHOPPER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "PaddyStrawChopper.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportMulcherSocieties(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.mulcherSocieties.length; i++) {
        const transaction = this.state.mulcherSocieties[i];
        let obj = {
          Division: transaction.division,
          District: transaction.district,
          "AR Circle": transaction.ar,
          Society: transaction.society,
          "MULCHER UTILIZATION": transaction["MULCHER UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.mulcherSocieties.length; i++) {
        const transaction = this.state.mulcherSocieties[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "MULCHER UTILIZATION": transaction["MULCHER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.mulcherSocieties.length; i++) {
        const transaction = this.state.mulcherSocieties[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            Division: transaction.division,
            District: transaction.district,
            "AR Circle": transaction.ar,
            Society: transaction.society,
            "MULCHER UTILIZATION": transaction["MULCHER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Mulcher.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportSocietiesSummary(selectedDivision = "", selectedDistrict = "") {
    let finalTransactions = [];
    if (selectedDivision === "" && selectedDistrict === "") {
      for (let i = 0; i < this.state.societyMachineSummary.length; i++) {
        const transaction = this.state.societyMachineSummary[i];
        let obj = {
          DIVISION: transaction.division,
          DISTRICT: transaction.district,
          "AR CIRCLE": transaction.ar,
          SOCIETY: transaction.society,

          "HAPPY SEEDER COUNT": transaction["HAPPY SEEDER COUNT"],
          "HAPPY SEEDER CHARGEABLE HRS":
            transaction["HAPPY SEEDER CHARGEABLE HRS"],
          "HAPPY SEEDER FREE HRS": transaction["HAPPY SEEDER FREE HRS"],
          "HAPPY SEEDER TOTAL HRS": transaction["HAPPY SEEDER TOTAL HRS"],
          "HAPPY SEEDER AVG": transaction["HAPPY SEEDER AVG"],
          "HAPPY SEEDER UTILIZATION": transaction["HAPPY SEEDER UTILIZATION"],

          "SUPER SEEDER COUNT": transaction["SUPER SEEDER COUNT"],
          "SUPER SEEDER CHARGEABLE HRS":
            transaction["SUPER SEEDER CHARGEABLE HRS"],
          "SUPER SEEDER FREE HRS": transaction["SUPER SEEDER FREE HRS"],
          "SUPER SEEDER TOTAL HRS": transaction["SUPER SEEDER TOTAL HRS"],
          "SUPER SEEDER AVG": transaction["SUPER SEEDER AVG"],
          "SUPER SEEDER UTILIZATION": transaction["SUPER SEEDER UTILIZATION"],

          "SURFACE SEEDER COUNT": transaction["SURFACE SEEDER COUNT"],
          "SURFACE SEEDER CHARGEABLE HRS":
            transaction["SURFACE SEEDER CHARGEABLE HRS"],
          "SURFACE SEEDER FREE HRS": transaction["SURFACE SEEDER FREE HRS"],
          "SURFACE SEEDER TOTAL HRS": transaction["SURFACE SEEDER TOTAL HRS"],
          "SURFACE SEEDER AVG": transaction["SURFACE SEEDER AVG"],
          "SURFACE SEEDER UTILIZATION":
            transaction["SURFACE SEEDER UTILIZATION"],

          "RMB PLOUGH COUNT": transaction["RMB PLOUGH COUNT"],
          "RMB PLOUGH CHARGEABLE HRS": transaction["RMB PLOUGH CHARGEABLE HRS"],
          "RMB PLOUGH FREE HRS": transaction["RMB PLOUGH FREE HRS"],
          "RMB PLOUGH TOTAL HRS": transaction["RMB PLOUGH TOTAL HRS"],
          "RMB PLOUGH AVG": transaction["RMB PLOUGH AVG"],
          "RMB PLOUGH UTILIZATION": transaction["RMB PLOUGH UTILIZATION"],

          "ROTAVATOR COUNT": transaction["ROTAVATOR COUNT"],
          "ROTAVATOR CHARGEABLE HRS": transaction["ROTAVATOR CHARGEABLE HRS"],
          "ROTAVATOR FREE HRS": transaction["ROTAVATOR FREE HRS"],
          "ROTAVATOR TOTAL HRS": transaction["ROTAVATOR TOTAL HRS"],
          "ROTAVATOR AVG": transaction["ROTAVATOR AVG"],
          "ROTAVATOR UTILIZATION": transaction["ROTAVATOR UTILIZATION"],

          "ZERO TILL DRILL COUNT": transaction["ZERO TILL DRILL COUNT"],
          "ZERO TILL DRILL CHARGEABLE HRS":
            transaction["ZERO TILL DRILL CHARGEABLE HRS"],
          "ZERO TILL DRILL FREE HRS": transaction["ZERO TILL DRILL FREE HRS"],
          "ZERO TILL DRILL TOTAL HRS": transaction["ZERO TILL DRILL TOTAL HRS"],
          "ZERO TILL DRILL AVG": transaction["ZERO TILL DRILL AVG"],
          "ZERO TILL DRILL UTILIZATION":
            transaction["ZERO TILL DRILL UTILIZATION"],

          "CHOPPER COUNT": transaction["CHOPPER COUNT"],
          "CHOPPER CHARGEABLE HRS": transaction["CHOPPER CHARGEABLE HRS"],
          "CHOPPER FREE HRS": transaction["CHOPPER FREE HRS"],
          "CHOPPER TOTAL HRS": transaction["CHOPPER TOTAL HRS"],
          "CHOPPER AVG": transaction["CHOPPER AVG"],
          "CHOPPER UTILIZATION": transaction["CHOPPER UTILIZATION"],

          "MULCHER COUNT": transaction["MULCHER COUNT"],
          "MULCHER CHARGEABLE HRS": transaction["MULCHER CHARGEABLE HRS"],
          "MULCHER FREE HRS": transaction["MULCHER FREE HRS"],
          "MULCHER TOTAL HRS": transaction["MULCHER TOTAL HRS"],
          "MULCHER AVG": transaction["MULCHER AVG"],
          "MULCHER UTILIZATION": transaction["MULCHER UTILIZATION"],
        };
        finalTransactions.push(obj);
      }
    }
    if (selectedDivision !== "") {
      for (let i = 0; i < this.state.societyMachineSummary.length; i++) {
        const transaction = this.state.societyMachineSummary[i];
        if (selectedDivision === transaction.division) {
          let obj = {
            DIVISION: transaction.division,
            DISTRICT: transaction.district,
            "AR CIRCLE": transaction.ar,
            SOCIETY: transaction.society,

            "HAPPY SEEDER COUNT": transaction["HAPPY SEEDER COUNT"],
            "HAPPY SEEDER CHARGEABLE HRS":
              transaction["HAPPY SEEDER CHARGEABLE HRS"],
            "HAPPY SEEDER FREE HRS": transaction["HAPPY SEEDER FREE HRS"],
            "HAPPY SEEDER TOTAL HRS": transaction["HAPPY SEEDER TOTAL HRS"],
            "HAPPY SEEDER AVG": transaction["HAPPY SEEDER AVG"],
            "HAPPY SEEDER UTILIZATION": transaction["HAPPY SEEDER UTILIZATION"],

            "SUPER SEEDER COUNT": transaction["SUPER SEEDER COUNT"],
            "SUPER SEEDER CHARGEABLE HRS":
              transaction["SUPER SEEDER CHARGEABLE HRS"],
            "SUPER SEEDER FREE HRS": transaction["SUPER SEEDER FREE HRS"],
            "SUPER SEEDER TOTAL HRS": transaction["SUPER SEEDER TOTAL HRS"],
            "SUPER SEEDER AVG": transaction["SUPER SEEDER AVG"],
            "SUPER SEEDER UTILIZATION": transaction["SUPER SEEDER UTILIZATION"],

            "SURFACE SEEDER COUNT": transaction["SURFACE SEEDER COUNT"],
            "SURFACE SEEDER CHARGEABLE HRS":
              transaction["SURFACE SEEDER CHARGEABLE HRS"],
            "SURFACE SEEDER FREE HRS": transaction["SURFACE SEEDER FREE HRS"],
            "SURFACE SEEDER TOTAL HRS": transaction["SURFACE SEEDER TOTAL HRS"],
            "SURFACE SEEDER AVG": transaction["SURFACE SEEDER AVG"],
            "SURFACE SEEDER UTILIZATION":
              transaction["SURFACE SEEDER UTILIZATION"],

            "RMB PLOUGH COUNT": transaction["RMB PLOUGH COUNT"],
            "RMB PLOUGH CHARGEABLE HRS":
              transaction["RMB PLOUGH CHARGEABLE HRS"],
            "RMB PLOUGH FREE HRS": transaction["RMB PLOUGH FREE HRS"],
            "RMB PLOUGH TOTAL HRS": transaction["RMB PLOUGH TOTAL HRS"],
            "RMB PLOUGH AVG": transaction["RMB PLOUGH AVG"],
            "RMB PLOUGH UTILIZATION": transaction["RMB PLOUGH UTILIZATION"],

            "ROTAVATOR COUNT": transaction["ROTAVATOR COUNT"],
            "ROTAVATOR CHARGEABLE HRS": transaction["ROTAVATOR CHARGEABLE HRS"],
            "ROTAVATOR FREE HRS": transaction["ROTAVATOR FREE HRS"],
            "ROTAVATOR TOTAL HRS": transaction["ROTAVATOR TOTAL HRS"],
            "ROTAVATOR AVG": transaction["ROTAVATOR AVG"],
            "ROTAVATOR UTILIZATION": transaction["ROTAVATOR UTILIZATION"],

            "ZERO TILL DRILL COUNT": transaction["ZERO TILL DRILL COUNT"],
            "ZERO TILL DRILL CHARGEABLE HRS":
              transaction["ZERO TILL DRILL CHARGEABLE HRS"],
            "ZERO TILL DRILL FREE HRS": transaction["ZERO TILL DRILL FREE HRS"],
            "ZERO TILL DRILL TOTAL HRS":
              transaction["ZERO TILL DRILL TOTAL HRS"],
            "ZERO TILL DRILL AVG": transaction["ZERO TILL DRILL AVG"],
            "ZERO TILL DRILL UTILIZATION":
              transaction["ZERO TILL DRILL UTILIZATION"],

            "CHOPPER COUNT": transaction["CHOPPER COUNT"],
            "CHOPPER CHARGEABLE HRS": transaction["CHOPPER CHARGEABLE HRS"],
            "CHOPPER FREE HRS": transaction["CHOPPER FREE HRS"],
            "CHOPPER TOTAL HRS": transaction["CHOPPER TOTAL HRS"],
            "CHOPPER AVG": transaction["CHOPPER AVG"],
            "CHOPPER UTILIZATION": transaction["CHOPPER UTILIZATION"],

            "MULCHER COUNT": transaction["MULCHER COUNT"],
            "MULCHER CHARGEABLE HRS": transaction["MULCHER CHARGEABLE HRS"],
            "MULCHER FREE HRS": transaction["MULCHER FREE HRS"],
            "MULCHER TOTAL HRS": transaction["MULCHER TOTAL HRS"],
            "MULCHER AVG": transaction["MULCHER AVG"],
            "MULCHER UTILIZATION": transaction["MULCHER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    if (selectedDistrict !== "") {
      for (let i = 0; i < this.state.societyMachineSummary.length; i++) {
        const transaction = this.state.societyMachineSummary[i];
        if (selectedDistrict === transaction.district) {
          let obj = {
            DIVISION: transaction.division,
            DISTRICT: transaction.district,
            "AR CIRCLE": transaction.ar,
            SOCIETY: transaction.society,

            "HAPPY SEEDER COUNT": transaction["HAPPY SEEDER COUNT"],
            "HAPPY SEEDER CHARGEABLE HRS":
              transaction["HAPPY SEEDER CHARGEABLE HRS"],
            "HAPPY SEEDER FREE HRS": transaction["HAPPY SEEDER FREE HRS"],
            "HAPPY SEEDER TOTAL HRS": transaction["HAPPY SEEDER TOTAL HRS"],
            "HAPPY SEEDER AVG": transaction["HAPPY SEEDER AVG"],
            "HAPPY SEEDER UTILIZATION": transaction["HAPPY SEEDER UTILIZATION"],

            "SUPER SEEDER COUNT": transaction["SUPER SEEDER COUNT"],
            "SUPER SEEDER CHARGEABLE HRS":
              transaction["SUPER SEEDER CHARGEABLE HRS"],
            "SUPER SEEDER FREE HRS": transaction["SUPER SEEDER FREE HRS"],
            "SUPER SEEDER TOTAL HRS": transaction["SUPER SEEDER TOTAL HRS"],
            "SUPER SEEDER AVG": transaction["SUPER SEEDER AVG"],
            "SUPER SEEDER UTILIZATION": transaction["SUPER SEEDER UTILIZATION"],

            "SURFACE SEEDER COUNT": transaction["SURFACE SEEDER COUNT"],
            "SURFACE SEEDER CHARGEABLE HRS":
              transaction["SURFACE SEEDER CHARGEABLE HRS"],
            "SURFACE SEEDER FREE HRS": transaction["SURFACE SEEDER FREE HRS"],
            "SURFACE SEEDER TOTAL HRS": transaction["SURFACE SEEDER TOTAL HRS"],
            "SURFACE SEEDER AVG": transaction["SURFACE SEEDER AVG"],
            "SURFACE SEEDER UTILIZATION":
              transaction["SURFACE SEEDER UTILIZATION"],

            "RMB PLOUGH COUNT": transaction["RMB PLOUGH COUNT"],
            "RMB PLOUGH CHARGEABLE HRS":
              transaction["RMB PLOUGH CHARGEABLE HRS"],
            "RMB PLOUGH FREE HRS": transaction["RMB PLOUGH FREE HRS"],
            "RMB PLOUGH TOTAL HRS": transaction["RMB PLOUGH TOTAL HRS"],
            "RMB PLOUGH AVG": transaction["RMB PLOUGH AVG"],
            "RMB PLOUGH UTILIZATION": transaction["RMB PLOUGH UTILIZATION"],

            "ROTAVATOR COUNT": transaction["ROTAVATOR COUNT"],
            "ROTAVATOR CHARGEABLE HRS": transaction["ROTAVATOR CHARGEABLE HRS"],
            "ROTAVATOR FREE HRS": transaction["ROTAVATOR FREE HRS"],
            "ROTAVATOR TOTAL HRS": transaction["ROTAVATOR TOTAL HRS"],
            "ROTAVATOR AVG": transaction["ROTAVATOR AVG"],
            "ROTAVATOR UTILIZATION": transaction["ROTAVATOR UTILIZATION"],

            "ZERO TILL DRILL COUNT": transaction["ZERO TILL DRILL COUNT"],
            "ZERO TILL DRILL CHARGEABLE HRS":
              transaction["ZERO TILL DRILL CHARGEABLE HRS"],
            "ZERO TILL DRILL FREE HRS": transaction["ZERO TILL DRILL FREE HRS"],
            "ZERO TILL DRILL TOTAL HRS":
              transaction["ZERO TILL DRILL TOTAL HRS"],
            "ZERO TILL DRILL AVG": transaction["ZERO TILL DRILL AVG"],
            "ZERO TILL DRILL UTILIZATION":
              transaction["ZERO TILL DRILL UTILIZATION"],

            "CHOPPER COUNT": transaction["CHOPPER COUNT"],
            "CHOPPER CHARGEABLE HRS": transaction["CHOPPER CHARGEABLE HRS"],
            "CHOPPER FREE HRS": transaction["CHOPPER FREE HRS"],
            "CHOPPER TOTAL HRS": transaction["CHOPPER TOTAL HRS"],
            "CHOPPER AVG": transaction["CHOPPER AVG"],
            "CHOPPER UTILIZATION": transaction["CHOPPER UTILIZATION"],

            "MULCHER COUNT": transaction["MULCHER COUNT"],
            "MULCHER CHARGEABLE HRS": transaction["MULCHER CHARGEABLE HRS"],
            "MULCHER FREE HRS": transaction["MULCHER FREE HRS"],
            "MULCHER TOTAL HRS": transaction["MULCHER TOTAL HRS"],
            "MULCHER AVG": transaction["MULCHER AVG"],
            "MULCHER UTILIZATION": transaction["MULCHER UTILIZATION"],
          };
          finalTransactions.push(obj);
        }
      }
    }
    const csvData = this.objToCSV(finalTransactions);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SocietyMachineSummary.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  renderYearSelection() {
    return (
      <React.Fragment>
        <br />
        <div className="row">
          <div className="col-md-4">
            <select
              className="form-control"
              value={this.state.selectedYearIndex}
              onChange={(evt) => {
                console.log("selectedYearIndex", evt.target.value);
                this.setState({ selectedYearIndex: evt.target.value });
              }}
            >
              {this.state.years.map((y, index) => (
                <option value={index}>
                  {y.DisplayStartDate} - {y.DisplayEndDate}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-warning btn-md"
              onClick={() => {
                // console.log('selectedYearIndex',this.state.selectedYearIndex)
                let startDate = new Date(
                  this.state.years[
                    Number(this.state.selectedYearIndex)
                  ].StartDate
                )
                  .toISOString()
                  .split("T")[0];
                let endDate = new Date(
                  this.state.years[Number(this.state.selectedYearIndex)].EndDate
                )
                  .toISOString()
                  .split("T")[0];

                // console.log('startDate',startDate);
                // console.log('endDate',endDate);

                this.loadData(startDate, endDate);
              }}
            >
              Refresh
            </button>
          </div>
        </div>
        <br />
      </React.Fragment>
    );
  }
  render() {
    return (
      <div>
        <Header menuSelected="home" {...this.props} />
        <main className="container">
          {/* <br /> */}

          {this.props.common.user &&
            this.props.common.user.Role !== "RCS" &&
            this.props.common.user.Role !== "JR" && (
              <div className="row">
                {this.props.common.society && (
                  <div className="col-md-6">
                    <div
                      className="my-3 p-3 bg-white rounded shadow-sm"
                      // style={{ minHeight: "190px", fontSize: "14px" }}
                    >
                      <h3>
                        {" "}
                        <span className="fa-stack fa-md">
                          <i className="fa fa-circle-thin fa-stack-2x"></i>
                          <i className="fa fa-university fa-stack-1x"></i>
                        </span>
                        {this.props.common.society.SOCIETY}
                      </h3>
                      {this.props.common.society.DIVISION &&
                        this.props.common.society.DIVISION !== "" && (
                          <div>
                            Division :&nbsp;{this.props.common.society.DIVISION}
                          </div>
                        )}
                      {this.props.common.society.DISTRICT &&
                        this.props.common.society.DISTRICT !== "" && (
                          <div>
                            District :&nbsp;{this.props.common.society.DISTRICT}
                          </div>
                        )}
                      {this.props.common.society.AR &&
                        this.props.common.society.AR !== "" && (
                          <div>
                            AR Circle:&nbsp;{this.props.common.society.AR}
                          </div>
                        )}
                      {this.props.common.society.VILLAGE &&
                        this.props.common.society.VILLAGE !== "" && (
                          <div>
                            Village :&nbsp;{this.props.common.society.VILLAGE}
                          </div>
                        )}
                      {this.props.common.society.INSPECTOR &&
                        this.props.common.society.INSPECTOR !== "" && (
                          <div>
                            Inspector :&nbsp;
                            {this.props.common.society.INSPECTOR +
                              " (" +
                              this.props.common.society.INSPECTORMOBILE +
                              ")"}{" "}
                          </div>
                        )}
                      <div style={{ marginTop: "10px" }}>
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            this.props.onHistory.push("/societyedit")
                          }
                          type="submit"
                        >
                          <i className="fa fa-edit" aria-hidden="true"></i>
                          &nbsp;&nbsp;View / Edit Machine Types
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.common.user &&
                  this.props.common.user.Role === "Secretary" && (
                    <div className="col-md-6">
                      <div
                        className="my-3 p-3 bg-white rounded shadow-sm"
                        // style={{ minHeight: "242px", fontSize: "14px" }}
                      >
                        <h3>
                          <span className="fa-stack fa-md">
                            <i className="fa fa-circle-thin fa-stack-2x"></i>
                            <i className="fa fa-user fa-stack-1x"></i>
                          </span>
                          {this.props.common.user.Name}
                        </h3>
                        <div>Mobile No : {this.props.common.user.PhoneNo}</div>
                        <div>Email ID : {this.props.common.user.EmailID}</div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          {this.props.common &&
            this.props.common.user &&
            (this.props.common.user.Role === "Inspector" ||
              this.props.common.user.Role === "AR" ||
              this.state.selectedAR !== "") && (
              <Reports
                showHeader={false}
                selectedAR={this.state.selectedAR}
                selectedYearIndex={this.state.selectedYearIndex}
                onBackClick={() => this.setState({ selectedAR: "" })}
                {...this.props}
              />
            )}
          {this.state.loading && <Loader show={this.state.loading} />}
          {this.props.common.user &&
            (this.props.common.user.Role === "RCS" ||
              this.props.common.user.Role === "JR") &&
            this.state.selectedDivision === "" &&
            !this.state.loading && (
              <React.Fragment>
                <br />
                <h5 style={{ textAlign: "center", color: "white" }}>
                  Avg. Utilization (hrs/machine)
                </h5>
                {this.renderYearSelection()}
                <div className="row">
                  <div className="col-4">
                    <button
                      type="button"
                      className="btn btn-warning pull-left"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportSocietiesSummary(
                          this.state.selectedDivision,
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Society-Wise Machine Summary
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12 rounded "
                    style={{ overflow: "auto" }}
                  >
                    <table
                      className="table table-striped table-bordered bg-white "
                      style={{ fontSize: "10px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              verticalAlign: "top",
                              color: "black",
                              backgroundColor: "#EDDC4D",
                            }}
                            rowSpan="2"
                          >
                            DIVISION
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            HAPPY SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            SUPER SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            SURFACE SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            RMB PLOUGH
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            ROTAVATOR
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            ZERO TILL DRILL
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            PADDY STRAW CHOPPER
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            MULCHER
                          </th>
                        </tr>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Free Hrs
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Chargeable Hrs
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {t["HAPPY SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["HAPPY SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["HAPPY SEEDER AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["HAPPY SEEDER FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["HAPPY SEEDER CHARGEABLE HRS"]).toFixed(
                                0
                              )}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["SUPER SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["SUPER SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["SUPER SEEDER AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["SUPER SEEDER FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["SUPER SEEDER CHARGEABLE HRS"]).toFixed(
                                0
                              )}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["SURFACE SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["SURFACE SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["SURFACE SEEDER AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["SURFACE SEEDER FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(
                                t["SURFACE SEEDER CHARGEABLE HRS"]
                              ).toFixed(0)}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["RMB PLOUGH COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["RMB PLOUGH PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["RMB PLOUGH AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["RMB PLOUGH FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["RMB PLOUGH CHARGEABLE HRS"]).toFixed(
                                0
                              )}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["ROTAVATOR COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["ROTAVATOR PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["ROTAVATOR AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["ROTAVATOR FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["ROTAVATOR CHARGEABLE HRS"]).toFixed(0)}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["ZERO TILL DRILL COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["ZERO TILL DRILL PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["ZERO TILL DRILL AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["ZERO TILL DRILL FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(
                                t["ZERO TILL DRILL CHARGEABLE HRS"]
                              ).toFixed(0)}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["CHOPPER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["CHOPPER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["CHOPPER AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["CHOPPER FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["CHOPPER CHARGEABLE HRS"]).toFixed(0)}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["MULCHER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["MULCHER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                              }}
                            >
                              {t["MULCHER AVG"]}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["MULCHER FREE HRS"]).toFixed(0)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {Number(t["MULCHER CHARGEABLE HRS"]).toFixed(0)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "HAPPY SEEDER TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "HAPPY SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["HAPPY SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "HAPPY SEEDER TOTAL FREE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "HAPPY SEEDER TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SUPER SEEDER TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SUPER SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["SUPER SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SUPER SEEDER TOTAL FREE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SUPER SEEDER TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SURFACE SEEDER TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SURFACE SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SURFACE SEEDER TOTAL AVG"
                              ]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SURFACE SEEDER TOTAL FREE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "SURFACE SEEDER TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal["RMB PLOUGH TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "RMB PLOUGH TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["RMB PLOUGH TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "RMB PLOUGH TOTAL FREE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "RMB PLOUGH TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal["ROTAVATOR TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ROTAVATOR TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["ROTAVATOR TOTAL AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ROTAVATOR TOTAL FREE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ROTAVATOR TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ZERO TILL DRILL TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ZERO TILL DRILL TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ZERO TILL DRILL TOTAL AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ZERO TILL DRILL TOTAL FREE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "ZERO TILL DRILL TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal["CHOPPER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["CHOPPER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["CHOPPER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal["CHOPPER TOTAL FREE HRS"]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "CHOPPER TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal["MULCHER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["MULCHER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.divisionTotal["MULCHER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal["MULCHER TOTAL FREE HRS"]
                            ).toFixed(0)}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.divisionTotal[
                                "MULCHER TOTAL CHARGEABLE HRS"
                              ]
                            ).toFixed(0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      HAPPY SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportHappySeederSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "15px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f["HAPPY SEEDER UTILIZATION"] ===
                                  "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f["HAPPY SEEDER UTILIZATION"] ===
                                  "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f["HAPPY SEEDER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f["HAPPY SEEDER UTILIZATION"] ===
                                  "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      SUPER SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportSuperSeederSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f["SUPER SEEDER UTILIZATION"] ===
                                  "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f["SUPER SEEDER UTILIZATION"] ===
                                  "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f["SUPER SEEDER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f["SUPER SEEDER UTILIZATION"] ===
                                  "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      SURFACE SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportSurfaceSeederSocieties("", "")
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                  "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                  "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                  "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                  "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      RMB PLOUGH
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportRMBPloughSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f["RMB PLOUGH UTILIZATION"] ===
                                  "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f["RMB PLOUGH UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f["RMB PLOUGH UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      ROTAVATOR
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportRotavatorSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f["ROTAVATOR UTILIZATION"] === "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f["ROTAVATOR UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      ZERO TILL DRILL
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportZTDSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                  "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                  "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                  "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                  "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      PADDY STRAW CHOPPER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportChopperSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["CHOPPER UTILIZATION"] === "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f["CHOPPER UTILIZATION"] === "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) => f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      MULCHER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() => this.onExportMulcherSocieties("", "")}
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DIVISION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.divisions.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDivisionSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["MULCHER UTILIZATION"] === "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["MULCHER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division === t.name &&
                                    f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f["MULCHER UTILIZATION"] === "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) => f["MULCHER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </React.Fragment>
            )}

          {/* District  */}
          {this.props.common.user &&
            (this.props.common.user.Role === "RCS" ||
              this.props.common.user.Role === "JR" ||
              this.props.common.user.Role === "DR") &&
            this.state.selectedDivision !== "" &&
            this.state.selectedDistrict === "" &&
            !this.state.loading && (
              <React.Fragment>
                <br />
                {(this.props.common.user.Role === "RCS" ||
                  this.props.common.user.Role === "JR") && (
                  <div className="row">
                    <div className="col-md-6">
                      <h3 style={{ color: "white" }}>
                        Division: {this.state.selectedDivision}
                      </h3>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="button"
                        className="btn btn-primary pull-right"
                        style={{ marginLeft: "5px" }}
                        onClick={() => this.setState({ selectedDivision: "" })}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning pull-right"
                        style={{ color: "white" }}
                        onClick={() =>
                          this.onExportTransactions(
                            this.state.startDate,
                            this.state.endDate
                          )
                        }
                      >
                        Export Transactions
                      </button>
                    </div>
                  </div>
                )}
                {this.props.common.user.Role === "DR" && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-warning pull-right"
                        style={{ color: "white" }}
                        onClick={() =>
                          this.onExportTransactions(
                            this.state.startDate,
                            this.state.endDate
                          )
                        }
                      >
                        Export Transactions
                      </button>
                    </div>
                  </div>
                )}
                {this.renderYearSelection()}
                <div className="row">
                  <h5 style={{ textAlign: "center", color: "white" }}>
                    Avg. Utilization (hrs/machine)
                  </h5>
                  <div className="row">
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-warning pull-left"
                        style={{ color: "white", marginBottom: "5px" }}
                        onClick={() =>
                          this.onExportSocietiesSummary(
                            this.state.selectedDivision,
                            ""
                          )
                        }
                      >
                        Export Society-Wise Machine Summary
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-md-12 rounded"
                    style={{ overflow: "auto" }}
                  >
                    <table
                      className="table table-striped table-bordered bg-white "
                      style={{ fontSize: "10px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              verticalAlign: "top",
                              color: "black",
                              backgroundColor: "#EDDC4D",
                            }}
                            rowSpan="2"
                          >
                            DISTRICT
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            HAPPY SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            SUPER SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            SURFACE SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            RMB PLOUGH
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            ROTAVATOR
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            ZERO TILL DRILL
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            PADDY STRAW CHOPPER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            MULCHER
                          </th>
                        </tr>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index) => (
                          <tr key={index}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {t["HAPPY SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["HAPPY SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["HAPPY SEEDER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["SUPER SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SUPER SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SUPER SEEDER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["SURFACE SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SURFACE SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SURFACE SEEDER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["RMB PLOUGH COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["RMB PLOUGH PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["RMB PLOUGH AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["ROTAVATOR COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ROTAVATOR PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ROTAVATOR AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["ZERO TILL DRILL COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ZERO TILL DRILL PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ZERO TILL DRILL AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["CHOPPER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["CHOPPER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["CHOPPER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["MULCHER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["MULCHER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["MULCHER AVG"]}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "HAPPY SEEDER TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "HAPPY SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["HAPPY SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "SUPER SEEDER TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "SUPER SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["SUPER SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "SURFACE SEEDER TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "SURFACE SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "SURFACE SEEDER TOTAL AVG"
                              ]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal["RMB PLOUGH TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "RMB PLOUGH TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["RMB PLOUGH TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal["ROTAVATOR TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "ROTAVATOR TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["ROTAVATOR TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "ZERO TILL DRILL TOTAL COUNT"
                              ]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "ZERO TILL DRILL TOTAL REV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal[
                                "ZERO TILL DRILL TOTAL AVG"
                              ]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal["CHOPPER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["CHOPPER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["CHOPPER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.districtTotal["MULCHER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["MULCHER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.districtTotal["MULCHER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      HAPPY SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportHappySeederSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                    "0 Utilization"
                                ).length
                              : this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                    "Upto 30 Hrs"
                                ).length
                              : this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                    "30-50 Hrs"
                                ).length
                              : this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                    "Above 50 Hrs"
                                ).length
                              : this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      SUPER SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportSuperSeederSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f["SUPER SEEDER UTILIZATION"] ===
                                    "0 Utilization"
                                ).length
                              : this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f["SUPER SEEDER UTILIZATION"] ===
                                    "Upto 30 Hrs"
                                ).length
                              : this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f["SUPER SEEDER UTILIZATION"] ===
                                    "30-50 Hrs"
                                ).length
                              : this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f["SUPER SEEDER UTILIZATION"] ===
                                    "Above 50 Hrs"
                                ).length
                              : this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      SURFACE SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportSurfaceSeederSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                    "0 Utilization"
                                ).length
                              : this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                    "Upto 30 Hrs"
                                ).length
                              : this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                    "30-50 Hrs"
                                ).length
                              : this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                    "Above 50 Hrs"
                                ).length
                              : this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      RMB PLOUGH
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportRMBPloughSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f["RMB PLOUGH UTILIZATION"] ===
                                    "0 Utilization"
                                ).length
                              : this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f["RMB PLOUGH UTILIZATION"] ===
                                    "Upto 30 Hrs"
                                ).length
                              : this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                                ).length
                              : this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f["RMB PLOUGH UTILIZATION"] ===
                                    "Above 50 Hrs"
                                ).length
                              : this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      ROTAVATOR
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportRotavatorSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f["ROTAVATOR UTILIZATION"] ===
                                    "0 Utilization"
                                ).length
                              : this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              : this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                                ).length
                              : this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f["ROTAVATOR UTILIZATION"] ===
                                    "Above 50 Hrs"
                                ).length
                              : this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      ZERO TILL DRILL
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportZTDSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.ztdSocieties.filter(
                                  (f) =>
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                    "0 Utilization"
                                ).length
                              : this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.ztdSocieties.filter(
                                  (f) =>
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                    "Upto 30 Hrs"
                                ).length
                              : this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.ztdSocieties.filter(
                                  (f) =>
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                    "30-50 Hrs"
                                ).length
                              : this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.ztdSocieties.filter(
                                  (f) =>
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                    "Above 50 Hrs"
                                ).length
                              : this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      PADDY STRAW CHOPPER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportChopperSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["CHOPPER UTILIZATION"] === "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.chopperSocieties.filter(
                                  (f) =>
                                    f["CHOPPER UTILIZATION"] === "0 Utilization"
                                ).length
                              : this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["CHOPPER UTILIZATION"] === "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.chopperSocieties.filter(
                                  (f) =>
                                    f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              : this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.chopperSocieties.filter(
                                  (f) =>
                                    f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              : this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.chopperSocieties.filter(
                                  (f) =>
                                    f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              : this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      MULCHER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportMulcherSocieties(
                          this.state.selectedDivision,
                          ""
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            DISTRICT
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.districts.map((t, index1) => (
                          <tr key={index1}>
                            <td
                              onClick={() =>
                                this.onDistrictSelected(
                                  t.name,
                                  this.state.startDate,
                                  this.state.endDate
                                )
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["MULCHER UTILIZATION"] === "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["MULCHER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.district === t.name &&
                                    f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f["MULCHER UTILIZATION"] === "0 Utilization"
                                ).length
                              : this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["MULCHER UTILIZATION"] === "0 Utilization"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              : this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f["MULCHER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              : this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["MULCHER UTILIZATION"] === "30-50 Hrs"
                                ).length}
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.props.common.user.Role === "DR"
                              ? this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              : this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.division ===
                                      this.state.selectedDivision &&
                                    f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                                ).length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
                <br />
              </React.Fragment>
            )}

          {/* AR CIRCLE  */}
          {this.props.common.user &&
            (this.props.common.user.Role === "RCS" ||
              this.props.common.user.Role === "JR" ||
              this.props.common.user.Role === "DR") &&
            this.state.selectedDistrict !== "" &&
            this.state.selectedAR === "" &&
            !this.state.loading && (
              <React.Fragment>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <h3 style={{ color: "white" }}>
                      District: {this.state.selectedDistrict}
                    </h3>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-primary pull-right"
                      onClick={() => this.setState({ selectedDistrict: "" })}
                    >
                      Back
                    </button>
                  </div>
                </div>
                {this.renderYearSelection()}
                <div className="row">
                  <h5 style={{ textAlign: "center", color: "white" }}>
                    Avg. Utilization (hrs/machine)
                  </h5>
                  <div className="row">
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn btn-warning pull-left"
                        style={{ color: "white", marginBottom: "5px" }}
                        onClick={() =>
                          this.onExportSocietiesSummary(
                            "",
                            this.state.selectedDistrict
                          )
                        }
                      >
                        Export Society-Wise Machine Summary
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-12 rounded"
                    style={{ overflow: "auto" }}
                  >
                    <table
                      className="table table-striped table-bordered bg-white "
                      style={{ fontSize: "10px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            style={{
                              verticalAlign: "top",
                              color: "black",
                              backgroundColor: "#EDDC4D",
                            }}
                            rowSpan="2"
                          >
                            AR CIRCLE
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            HAPPY SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            SUPER SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            SURFACE SEEDER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            RMB PLOUGH
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            ROTAVATOR
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            ZERO TILL DRILL
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#92D050",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            PADDY STRAW CHOPPER
                          </th>
                          <th
                            scope="col"
                            colSpan="3"
                            style={{
                              backgroundColor: "#EDDC4D",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            MULCHER
                          </th>
                        </tr>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>

                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#343a40",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            No. of Machines
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Prev Avg. Utilization
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              borderColor: "#454d55",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Avg. Utilization
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index) => (
                          <tr key={index}>
                            <td
                              onClick={() =>
                                this.setState({ selectedAR: t.name })
                              }
                            >
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {t["HAPPY SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["HAPPY SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["HAPPY SEEDER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["SUPER SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SUPER SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SUPER SEEDER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["SURFACE SEEDER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SURFACE SEEDER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["SURFACE SEEDER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["RMB PLOUGH COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["RMB PLOUGH PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["RMB PLOUGH AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["ROTAVATOR COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ROTAVATOR PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ROTAVATOR AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["ZERO TILL DRILL COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ZERO TILL DRILL PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["ZERO TILL DRILL AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["CHOPPER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["CHOPPER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["CHOPPER AVG"]}
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {t["MULCHER COUNT"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["MULCHER PREV AVG"]}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#7F7F7F",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              {t["MULCHER AVG"]}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.arTotal["HAPPY SEEDER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["HAPPY SEEDER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["HAPPY SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.arTotal["SUPER SEEDER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["SUPER SEEDER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["SUPER SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.arTotal["SURFACE SEEDER TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal[
                                "SURFACE SEEDER TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["SURFACE SEEDER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.arTotal["RMB PLOUGH TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["RMB PLOUGH TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["RMB PLOUGH TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.arTotal["ROTAVATOR TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["ROTAVATOR TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["ROTAVATOR TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(
                              this.state.arTotal["ZERO TILL DRILL TOTAL COUNT"]
                            )}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal[
                                "ZERO TILL DRILL TOTAL PREV AVG"
                              ]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["ZERO TILL DRILL TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(this.state.arTotal["CHOPPER TOTAL COUNT"])}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["CHOPPER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["CHOPPER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {Number(this.state.arTotal["MULCHER TOTAL COUNT"])}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["MULCHER TOTAL PREV AVG"]
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#7F7F7F",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            {Number(
                              this.state.arTotal["MULCHER TOTAL AVG"]
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      HAPPY SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportHappySeederSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.happySeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["HAPPY SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["HAPPY SEEDER UTILIZATION"] ===
                                    "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["HAPPY SEEDER UTILIZATION"] ===
                                    "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["HAPPY SEEDER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.happySeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["HAPPY SEEDER UTILIZATION"] ===
                                    "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      SUPER SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportSuperSeederSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.superSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SUPER SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SUPER SEEDER UTILIZATION"] ===
                                    "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SUPER SEEDER UTILIZATION"] ===
                                    "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SUPER SEEDER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.superSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SUPER SEEDER UTILIZATION"] ===
                                    "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      SURFACE SEEDER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportSurfaceSeederSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.surfaceSeederSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["SURFACE SEEDER UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                    "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                    "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                    "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.surfaceSeederSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["SURFACE SEEDER UTILIZATION"] ===
                                    "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      RMB PLOUGH
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportRMBPloughSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rmbPloughSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["RMB PLOUGH UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["RMB PLOUGH UTILIZATION"] ===
                                    "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["RMB PLOUGH UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["RMB PLOUGH UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rmbPloughSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["RMB PLOUGH UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      ROTAVATOR
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportRotavatorSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.rotavatorSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ROTAVATOR UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ROTAVATOR UTILIZATION"] === "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ROTAVATOR UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ROTAVATOR UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.rotavatorSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ROTAVATOR UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      ZERO TILL DRILL
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportZTDSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.ztdSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["ZERO TILL DRILL UTILIZATION"] ===
                                      "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                    "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                    "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                    "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.ztdSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["ZERO TILL DRILL UTILIZATION"] ===
                                    "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      PADDY STRAW CHOPPER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportChopperSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["CHOPPER UTILIZATION"] === "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.chopperSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["CHOPPER UTILIZATION"] === "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["CHOPPER UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["CHOPPER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.chopperSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["CHOPPER UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12">
                    <h5 style={{ textAlign: "center", color: "white" }}>
                      MULCHER
                    </h5>
                    <button
                      type="button"
                      className="btn btn-warning pull-right"
                      style={{ color: "white", marginBottom: "5px" }}
                      onClick={() =>
                        this.onExportMulcherSocieties(
                          "",
                          this.state.selectedDistrict
                        )
                      }
                    >
                      Export Data
                    </button>
                    <table
                      className="table table-bordered bg-white table-striped "
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            scope="col"
                            colSpan="5"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <span>
                              No of Societies in different Average Utilization
                              Bands
                            </span>
                          </th>
                        </tr>{" "}
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            AR CIRCLE
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            0 UTILIZATION
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            UPTO 30 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            30-50 HRS
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            ABOVE 50 HRS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ars.map((t, index1) => (
                          <tr key={index1}>
                            <td>
                              <a href="#">{t.name}</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["MULCHER UTILIZATION"] === "0 Utilization"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["MULCHER UTILIZATION"] === "30-50 Hrs"
                                ).length
                              }
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {
                                this.state.mulcherSocieties.filter(
                                  (f) =>
                                    f.ar === t.name &&
                                    f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                                ).length
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ fontWeight: "bold" }}>TOTALS</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["MULCHER UTILIZATION"] === "0 Utilization"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["MULCHER UTILIZATION"] === "Upto 30 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["MULCHER UTILIZATION"] === "30-50 Hrs"
                              ).length
                            }
                          </td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {
                              this.state.mulcherSocieties.filter(
                                (f) =>
                                  f.district === this.state.selectedDistrict &&
                                  f["MULCHER UTILIZATION"] === "Above 50 Hrs"
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
                <br />
              </React.Fragment>
            )}
        </main>
      </div>
    );
  }
}

export default Home;
